import axios from "axios";
import { ethers } from "ethers";
import { useMutation } from "react-query";
import { apiRoute } from "../utils/api";

function useLoginUser() {
  const { mutate, mutateAsync, isLoading, isSuccess, isError, data } = useMutation(async () => {
    try {
      if (!(window as any).ethereum) throw new Error("Install Metamask in order to log in.");

      const accounts = await (window as any).ethereum.request({
        method: "eth_requestAccounts"
      });
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(accounts[0]);
      const address = await signer.getAddress();

      const result = await axios.post(`${apiRoute}/auth/login`, { message: accounts[0], signature, address }).then(x => x.data);
      return result;
    } catch (err: any) {
      console.log("login error occurred", err);
      throw err;
    }
  });

  return { mutate, mutateAsync, isLoading, isSuccess, isError, data };
}

export { useLoginUser };
