import gsap, { Power1 } from "gsap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrWin from "../../assets/img/arrowSpinnerWinItem.png";
import { LootItem } from "../common/LootItem";

import { LootboxItem } from "../../utils/commonTypes";

type RoulettePropsType = {
  wonItems: LootboxItem[] | null;
  items: LootboxItem[];
  spinning: boolean;
  setSpinning: (spinning: boolean) => void;
  setShowWonItems: (spinning: boolean) => void;
};

const itemWidth = 220;
const fullSpinsCount = 2;
const defaultSpinDuration = 3.5;

export const Roulette = (props: RoulettePropsType) => {
  const [items, setItems] = useState<LootboxItem[]>([]);
  const [itemBatchesNeededForCover, setItemBatchesNeededForCover] = useState<number>(1);
  const [itemsNeededForCover, setItemsNeededForCover] = useState<number>(1);
  const desiredImg = useRef<HTMLImageElement>(null);
  const listsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const itemsCount = props.items.length;
  const [translateX, setTranslateX] = useState<number>(0);
  const [spinDuration, setSpinDuration] = useState<number>(defaultSpinDuration);

  useEffect(() => {
    if (wrapperRef.current) {
      const itemsForCover = Math.ceil(wrapperRef.current.clientWidth / itemWidth);
      setItemsNeededForCover(itemsForCover);
      const itemBatchesForCover = Math.ceil(itemsForCover / itemsCount);
      setItemBatchesNeededForCover(itemBatchesForCover);
      const totalBatchesNeeded = (1 + fullSpinsCount + 2) * itemBatchesForCover;
      const itemsList = Array(totalBatchesNeeded)
        .fill(props.items)
        .flat()
        .slice(itemBatchesForCover * itemsCount - Math.ceil((wrapperRef.current.clientWidth - itemWidth) / 2 / itemWidth));
      setItems(itemsList);
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (props.spinning && listsRef.current && props.wonItems) rouletteSpin(props.wonItems[0].id!);
  }, [props.wonItems, props.spinning]);

  const rouletteSpin = (itemId: number) => {
    const resetSpinDuration = 0.05;
    setSpinDuration(resetSpinDuration);
    setTranslateX(0);
    setTimeout(() => {
      setSpinDuration(defaultSpinDuration);
      const itemIndex = props.items.findIndex(x => x.id === itemId);
      const toTranslateX = -((itemIndex + Math.max(Math.random() * 0.9, 0.2) + fullSpinsCount * itemBatchesNeededForCover * itemsCount) * itemWidth - 110);
      setTranslateX(toTranslateX);
      setTimeout(() => {
        props.setSpinning(false);
        props.setShowWonItems(true);
      }, defaultSpinDuration * 1000);
    }, resetSpinDuration * 1000);
  };

  return (
    <RouletteContainer>
      <RouletteIndicator>
        <img src={arrWin} alt="" />
        <img src={arrWin} alt="" />
      </RouletteIndicator>
      <RouletteImagesWrapper ref={wrapperRef}>
        <RouletteShadow degree={270} />
        <RouletteShadow style={{ position: "absolute", right: 0 }} degree={90} />
        <RouletteImagesList
          ref={listsRef}
          itemsOffsetLeft={Math.ceil((itemsNeededForCover - 1) / 2) * itemWidth}
          translateX={translateX}
          spinDuration={spinDuration}
          id="roulette-images-list"
        >
          {items.map((item: LootboxItem, idx: number) => (
            <LootItem key={idx} item={item} settings={{ hideCategory: true, hidePrice: true, height: "240px", tier: { hideRight: true } }} />
          ))}
        </RouletteImagesList>
      </RouletteImagesWrapper>
    </RouletteContainer>
  );
};

const RouletteShadow = styled.div<{ degree: number }>`
  height: 240px;
  z-index: 3;
  width: 20%;
  background: linear-gradient(${props => props.degree}deg, #0a0b0d 0%, rgba(10, 11, 13, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const RouletteContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const RouletteImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const RouletteImagesList = styled.div<{ itemsOffsetLeft: number; spinDuration: number; translateX: number }>`
  position: absolute;
  left: calc((47% + -65px) - ${props => props.itemsOffsetLeft}px);
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  transition: ${props => props.spinDuration}s transform;
  transform: translateX(${props => props.translateX}px);
`;

const RouletteIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 47%;
  margin: 0;
  height: 100%;
  background: none;
  z-index: 1;
  > img:last-child {
    transform: rotate(180deg);
  }
`;
