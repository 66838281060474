import styled from "styled-components";
import "./Progress.css";
import { useContext } from "react";
import garageHeaderBg from "../../assets/img/backgroundGarage.png";
import { GlobalContext, GlobalContextType } from "../../App";
import { useGetAllRobotsInProfile } from "../../hooks/useGetAllRobotsInProfile";
import { GarageProgress } from "./GarageProgress";

export const Progress = () => {
  const { userInfo } = useContext(GlobalContext) as GlobalContextType;
  const { data: robots } = useGetAllRobotsInProfile();
  console.log(
    "filtered robots",
    robots?.filter(x => x.garage != null)
  );
  return (
    <ProgressWrapper>{userInfo && userInfo.garages.sort((a, b) => a.number - b.number).map(garage => <GarageProgress garage={garage} pass={userInfo.pass} />)}</ProgressWrapper>
  );
};

const ProgressWrapper = styled.div`
  padding-left: 40px;
  background-image: url(${garageHeaderBg});
  background-repeat: no-repeat;
  min-height: inherit;
  height: 100%;
  background-size: contain;
`;
