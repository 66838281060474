import { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { apiRoute, imageRoute } from "../../utils/api";
import authHeader from "../../utils/auth-header";
import "./AdminProgress.css";
import { Modal } from "../common/Modal";
import { PartPicker } from "../common/PartPicker/PartPicker";
import { checkImageSrc } from "../../utils/commonFunctions";
import { Level, Lootbox, PartType } from "../../utils/commonTypes";
import mipCoin from "../../assets/img/coin200x200.png";
import { TedButton } from "../common/Button/TedButton";
import { useHistory } from "react-router-dom";
import { LootboxPicker } from "../common/LootboxPicker/LootboxPicker";

const rewardTypeColors: any = {
  lootbox: "red",
  part: "blue",
  tokens: "green"
};

export const AdminProgress = () => {
  const { data } = useQuery(["getLevels"], () =>
    axios
      .get<Level[]>(`${apiRoute}/admin/levels`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );

  const { data: dailyXPData } = useQuery(["getDailyXP"], () =>
    axios
      .get<{ daily_xp: number }>(`${apiRoute}/admin/daily-xp`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );

  const history = useHistory();
  const [levelsData, setLevelsData] = useState<Level[]>([]);
  const [pickModal, setPickModal] = useState<{ level: number; pass_type: string } | null>(null);
  const [visiblePicker, setVisiblePicker] = useState<string | null>(null);
  const [tokensToAssign, setTokensToAssign] = useState<number>(10);
  const [dailyXP, setDailyXP] = useState<number>(0);

  const { mutateAsync: saveMutation, isLoading: isSaving } = useMutation({
    mutationFn: (data: any) =>
      axios.put(`${apiRoute}/admin/levels`, data, {
        headers: authHeader()
      })
  });

  const { mutateAsync: saveDailyXPMutation, isLoading: isSavingDailyXP } = useMutation({
    mutationFn: (data: any) =>
      axios.put(`${apiRoute}/admin/daily-xp`, data, {
        headers: authHeader()
      })
  });

  useEffect(() => {
    if (data) setLevelsData(data);
  }, [data]);

  useEffect(() => {
    if (dailyXPData) setDailyXP(dailyXPData.daily_xp);
  }, [dailyXPData]);

  const save = async () => {
    if (!levelsData.length) return alert("Levels can't be empty");

    const data = [...levelsData].sort((a, b) => a.level - b.level);
    for (let i = 0; i < data.length; i++) {
      const level = data[i];
      if (!level.free_reward_type || !level.premium_reward_type) return alert(`You must set rewards for level ${level.level}`);
      else if (level.xp_required == null || level.xp_required < 0) return alert(`You must set required XP for level ${level.level}`);
      else if (i > 0 && level.xp_required <= data[i - 1].xp_required!) {
        console.log(level.xp_required, data[i - 1].xp_required);
        return alert("XP must be ascending");
      }
    }

    saveMutation(
      data.map(x => ({
        level: x.level,
        xp_required: x.xp_required,
        free_reward_type: x.free_reward_type,
        free_reward_id: x.free_reward_id,
        free_token_reward: x.free_token_reward,
        premium_reward_type: x.premium_reward_type,
        premium_reward_id: x.premium_reward_id,
        premium_token_reward: x.premium_token_reward
      }))
    )
      .then(x => alert("Levels saved."))
      .catch(e => alert("An error occurred. " + e.message));
  };

  const saveDailyXP = async () => {
    await saveDailyXPMutation({ xp: dailyXP });
    alert("Daily XP saved");
  };

  const addRow = (idx: number) => {
    const newRow: Level = {
      level: -1,
      xp_required: null,
      next_level_xp_required: null,
      free_reward_type: null,
      free_reward_id: null,
      free_token_reward: null,
      premium_reward_type: null,
      premium_reward_id: null,
      premium_token_reward: null
    };
    let newLevelsData = [...levelsData];
    newLevelsData.splice(idx, 0, newRow);
    newLevelsData.forEach((x, idx) => (x.level = idx + 1));
    setLevelsData(newLevelsData);
  };

  const updateLevelField = (level: number, value: any, field: string) => {
    console.log("updateLevelField", level, value, field);
    const newLevelsData: Level[] = [...levelsData];
    const idx = newLevelsData.findIndex(x => x.level == level);
    (newLevelsData as any)[idx][field] = value;
    setLevelsData(newLevelsData);
  };

  const removeLevel = (idx: number) => {
    let newLevelsData = [...levelsData];
    newLevelsData.splice(idx, 1);
    newLevelsData.forEach((x, idx) => (x.level = idx + 1));
    setLevelsData(newLevelsData);
  };

  const assignPartToLevel = (part: PartType) => {
    updateLevelField(pickModal!.level, part.id, `${pickModal!.pass_type}_reward_id`);
    updateLevelField(pickModal!.level, "part", `${pickModal!.pass_type}_reward_type`);
    updateLevelField(pickModal!.level, part, `${pickModal!.pass_type}_part`);
    setPickModal(null);
    setVisiblePicker(null);
  };

  const assignLootboxToLevel = (lootbox: Lootbox) => {
    updateLevelField(pickModal!.level, lootbox.id, `${pickModal!.pass_type}_reward_id`);
    updateLevelField(pickModal!.level, "lootbox", `${pickModal!.pass_type}_reward_type`);
    updateLevelField(pickModal!.level, lootbox, `${pickModal!.pass_type}_lootbox`);
    setPickModal(null);
    setVisiblePicker(null);
  };

  const assignTokensToLevel = (tokens: number) => {
    updateLevelField(pickModal!.level, "tokens", `${pickModal!.pass_type}_reward_type`);
    updateLevelField(pickModal!.level, tokens, `${pickModal!.pass_type}_token_reward`);
    setPickModal(null);
    setVisiblePicker(null);
  };

  return (
    <AdminContentWrapper>
      <h1 style={{ fontWeight: 600, fontSize: "45px", lineHeight: "48px", marginBottom: "50px" }}>Progress</h1>
      <div className="flex justify-between" style={{ width: "90%", marginBottom: "15px" }}>
        <div style={{ width: "140px" }}>
          <TedButton onclick={save} size="small" color="green" width="140" active={false}>
            Save
          </TedButton>
        </div>
        <div className="flex justify-center items-center gap-[15px]">
          <span className="text-center">Daily XP: </span>
          <input type="number" value={dailyXP} onChange={e => setDailyXP(parseInt(e.target.value))}></input>
          <div style={{ width: "140px" }}>
            <TedButton onclick={saveDailyXP} size="small" color="green" width="140" active={false}>
              Save Daily XP
            </TedButton>
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Level</th>
            <th>XP required</th>
            <th colSpan={2}>Free reward</th>
            <th colSpan={2}>Premium reward</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!levelsData.length && (
            <tr>
              <td colSpan={7}>
                <TedButton onclick={() => addRow(0)} style={{ margin: "15px 0" }} size="small" color="green" width="140" active={false}>
                  Add row
                </TedButton>
              </td>
            </tr>
          )}
          {levelsData.map((level, idx) => (
            <tr key={idx}>
              <td style={{ position: "relative" }}>
                <AddRowButton onClick={() => addRow(idx + 1)}>+</AddRowButton>
                {level.level}
              </td>
              <td>
                <input type="number" value={level.xp_required ?? 0} onChange={e => updateLevelField(level.level, parseInt(e.target.value), "xp_required")} />
              </td>
              <td style={{ position: "relative" }}>
                {/* {level.free_reward_type && <RewardType reward_type={level.free_reward_type}>{level.free_reward_type}</RewardType>} */}
                {level.free_reward_type == "part" ? (
                  <Reward>
                    <img src={`${imageRoute}${checkImageSrc(level.free_part!.images, "Thumbnail_Layer", level.free_part!.section)}`} alt="" />
                    <span>{level.free_part!.name}</span>
                  </Reward>
                ) : level.free_reward_type == "lootbox" ? (
                  <Reward style={{ cursor: "pointer" }} onClick={() => history.push(`/admin/lootbox/1/case/${level.free_lootbox!.id}`)}>
                    <img src={`${imageRoute}lootboxes/${level.free_lootbox!.image}`} alt="" />
                    <span>{level.free_lootbox!.name}</span>
                  </Reward>
                ) : level.free_token_reward ? (
                  <Reward>
                    <img src={mipCoin} />
                    <span>{level.free_token_reward}</span>
                  </Reward>
                ) : null}
              </td>
              <td>
                <TedButton
                  onclick={() => setPickModal({ level: level.level, pass_type: "free" })}
                  style={{ margin: "15px 0" }}
                  size="small"
                  color="green"
                  width="140"
                  active={false}
                >
                  {level.free_reward_type ? "Change reward" : "Pick reward"}
                </TedButton>
              </td>
              <td style={{ position: "relative" }}>
                {/* {level.premium_reward_type && <RewardType reward_type={level.premium_reward_type}>{level.premium_reward_type}</RewardType>} */}
                {level.premium_reward_type == "part" ? (
                  <Reward>
                    <img src={`${imageRoute}${checkImageSrc(level.premium_part!.images, "Thumbnail_Layer", level.premium_part!.section)}`} alt="" />
                    <span>{level.premium_part!.name}</span>
                  </Reward>
                ) : level.premium_reward_type == "lootbox" ? (
                  <Reward onClick={() => history.push(`/admin/lootbox/1/case/${level.premium_lootbox!.id}`)} style={{ cursor: "pointer" }}>
                    <img src={`${imageRoute}lootboxes/${level.premium_lootbox!.image}`} alt="" />
                    <span>{level.premium_lootbox!.name}</span>
                  </Reward>
                ) : level.premium_token_reward ? (
                  <Reward>
                    <img src={mipCoin} />
                    <span>{level.premium_token_reward}</span>
                  </Reward>
                ) : null}
              </td>
              <td>
                <TedButton
                  onclick={() => setPickModal({ level: level.level, pass_type: "premium" })}
                  style={{ margin: "15px 0" }}
                  size="small"
                  color="green"
                  width="140"
                  active={false}
                >
                  {level.premium_reward_type ? "Change reward" : "Pick reward"}
                </TedButton>
              </td>
              <td>
                <RemoveButton onClick={() => removeLevel(idx)}>✕</RemoveButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal visible={pickModal != null} onClose={() => setPickModal(null)}>
        <PickRewardModal>
          <div onClick={() => setVisiblePicker("part")}>Part</div>
          <div onClick={() => setVisiblePicker("lootbox")}>Lootbox</div>
          <div onClick={() => setVisiblePicker("tokens")}>Tokens</div>
          {visiblePicker == "tokens" && (
            <div className="tokensInput" style={{ marginTop: "15px" }}>
              <input type="number" placeholder="Tokens" onChange={e => setTokensToAssign(parseInt(e.target.value))} />
              <TedButton
                onclick={() => {
                  tokensToAssign <= 0 ? alert("Tokens must be greater than 0.") : assignTokensToLevel(tokensToAssign);
                }}
                style={{ margin: "15px 0" }}
                size="small"
                color="green"
                width="140"
                active={false}
              >
                Ok
              </TedButton>
            </div>
          )}
        </PickRewardModal>
      </Modal>
      <Modal
        visible={visiblePicker != null && ["part", "lootbox"].includes(visiblePicker)}
        contentStyle={{ width: "90%", height: "80%", overflow: "scroll" }}
        onClose={() => setVisiblePicker(null)}
      >
        {visiblePicker == "part" ? <PartPicker onPick={assignPartToLevel} /> : <LootboxPicker onPick={assignLootboxToLevel} />}
      </Modal>
    </AdminContentWrapper>
  );
};

const RemoveButton = styled.div`
  color: #bd0000;
  border: 1px solid #bd0000;
  padding: 4px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: red;
    border-color: red;
  }
`;

const Reward = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  img {
    width: 80px;
  }

  span {
    width: 200px;
    text-align: center;
    margin-left: 15px;
  }
`;

const RewardType = styled.div<{ reward_type: string }>`
  background-color: ${props => rewardTypeColors[props.reward_type]};
  text-transform: uppercase;
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
  width: 50px;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  left: 40px;
`;

const Table = styled.table`
  width: 90%;
  display: block;
  th,
  td {
    vertical-align: middle;
  }
`;

const AddRowButton = styled.div`
  position: absolute;
  display: none;
  bottom: -11px;
  left: -11px;
  border: 1px solid #1fe098;
  background: #1fe098;
  color: #050b1c;
  z-index: 10;
  border-radius: 50%;
  padding: 1px 5px;
  cursor: pointer;
  :hover {
    background: #0b8a5a;
    border-color: #0b8a5a;
    color: white;
  }

  td:first-child:hover & {
    display: block;
  }
`;

const AdminContentWrapper = styled.div`
  color: #ffffff;
  margin: -50px 0 -50px 0;
  min-height: calc(100vh - 90px);
  background: #050b1c;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;
`;

const PickRewardModal = styled.div`
  background-color: #0e141a;
  border-radius: 5px;
  width: 300px;
  min-height: 270px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  > div:not(.tokensInput) {
    cursor: pointer;
    width: 200px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    padding: 25px;
    color: white;
    border: 1px solid black;
    cursor: pointer;
    &:hover {
      background: #1fe098;
      color: black;
    }
  }
`;
