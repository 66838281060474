import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";

function usePostBuyLootbox() {
  const context = useContext(Context);

  const { mutate, isLoading, data } = useMutation(
    (data: { lootboxId: number; quantity: number }) => axios.post(`${apiRoute}/lootboxes/buy`, data, { headers: authHeader() }),
    {
      onSuccess: (data, variables) => {
        context?.setMessage(`Lootbox${variables.quantity > 1 ? "es" : ""} successfully bought.`);
        queryClient.invalidateQueries("getAdminLootBox");
        queryClient.invalidateQueries("getProfile");
      }
    }
  );

  return { mutate, isLoading, data };
}

export { usePostBuyLootbox };
