import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../assets/img/arrow-bottom.svg";
import { useMoveRobotToGarage } from "../../../hooks/useMoveRobotToGarage";
import { RobotType } from "../../../utils/commonTypes";
import { ModalMIPPicker } from "../Modals/ModalMIPPicker";
import { CircleRobotProgress } from "./CircleRobotProgress";
import { DropdownAbout } from "./DropdownAbout";

type MenuRobotProgressPropsType = {
  garage_number: number;
  currentExp: number;
  needExp: number;
  level: number;
  marginRight?: number;
  unActive?: boolean;
  mobile?: boolean;
  robot: RobotType | undefined;
  locked: boolean;
};

export const MenuRobotProgress = (props: MenuRobotProgressPropsType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const robot = props.robot;
  const [mipPickModalVisible, setMipPickModalVisible] = useState<boolean>(false);
  const { mutateAsync: moveRobotToGarage, isLoading: moveRobotLoading } = useMoveRobotToGarage();

  const confirmRobot = async (new_robot: RobotType) => {
    await moveRobotToGarage({
      robotId: new_robot.id,
      garage_number: props.garage_number
    });
    setMipPickModalVisible(false);
  };

  useEffect(() => {
    if (isOpen) {
      const onClick = (e: any) => ref.current?.contains(e.target) || setIsOpen(false);
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [isOpen]);

  return (
    <>
      <MenuRobotProgressWrapper
        ref={ref}
        locked={props.locked}
        mr={props.marginRight || 0}
        onClick={props.mobile ? () => {} : () => !props.locked && setIsOpen(!isOpen)}
        open={isOpen}
      >
        <CircleRobotProgress robot={robot} currentExp={props.currentExp} needExp={props.needExp} level={props.unActive ? 0 : props.level} />

        {robot ? (
          <>
            <MiddleTextWrapper>
              <h3>{robot.status == "frame" ? "Frame" : robot.name}</h3>
              <p>
                {props.currentExp} / {props.needExp} XP
              </p>
            </MiddleTextWrapper>
            {!props.unActive && !props.mobile && <ArrowSvgIcon />}
            <DropdownAbout
              visible={isOpen && !props.unActive && !props.mobile}
              image={robot.image}
              name={robot.name}
              currentExp={props.currentExp}
              needExp={props.needExp}
              level={props.level}
              robot={props.robot!}
              garage_number={props.garage_number}
            />
          </>
        ) : (
          <span className="mr-[5px] text-white text-[14px] hover:text-[#28efa8]" onClick={() => !props.locked && setMipPickModalVisible(true)}>
            Select MIP
          </span>
        )}
      </MenuRobotProgressWrapper>
      <ModalMIPPicker visible={mipPickModalVisible} processing={moveRobotLoading} onClose={() => setMipPickModalVisible(false)} onSelect={confirmRobot} status="NFT" />
    </>
  );
};

const MenuRobotProgressWrapper = styled.div<{ open: boolean; mr: number; locked: boolean }>`
  font-family: Bahnschrift;
  width: 211px;
  height: 66px;
  background: linear-gradient(90deg, #151b24 0%, #10161c 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position:relative;
  margin-right: ${({ mr }) => mr}px;
  ${({ locked }) =>
    locked
      ? `filter: grayscale(1) brightness(0.9);
        cursor: not-allowed;
      `
      : `cursor: pointer;`}

  :hover {
    background: #1a2029;
  }

  svg{
    ${props => props.open && `transform: rotate(180deg);`}
    transition: .5s transform;
    } 
  }

  @media(max-width: 1440px) {
    width: 180px;
  }

  @media(max-width: 1366px) {
    width: 175px;
  }

  @media(max-width: 1280px) {
    width: 168px;
  }

  @media (max-width:700px){
    justify-content:space-around;
    margin: 0 0 15px 0;
  }

`;

const MiddleTextWrapper = styled.div`
  margin-right: 50px;
  @media (max-width: 1440px) {
    margin-right: 18px;
  }

  @media (max-width: 550px) {
    display: flex;
    margin: 0;
    width: 60%;
    justify-content: space-around;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 4px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const ArrowSvgIcon = styled(Arrow)``;
