import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import light from "../../../assets/img/dropdownLight.png";
import { useMoveRobotToGarage } from "../../../hooks/useMoveRobotToGarage";
import { useRemoveFromGarage } from "../../../hooks/useRemoveFromGarage";
import { RobotType } from "../../../utils/commonTypes";
import { LineProgress } from "../LineProgress";
import { CustomLoader } from "../Loader";
import { ModalMIPPicker } from "../Modals/ModalMIPPicker";

type DropdownPropsType = {
  currentExp: number;
  needExp: number;
  level: number;
  name: string;
  image: string;
  visible: boolean;
  robot: RobotType;
  garage_number: number;
};

export const DropdownAbout = (props: DropdownPropsType) => {
  const [mipPickModalVisible, setMipPickModalVisible] = useState<boolean>(false);
  const { mutateAsync: moveRobotToGarage, isLoading: moveRobotLoading } = useMoveRobotToGarage();
  const { mutate: removeRobotFromGarage, isLoading: removeRobotLoading } = useRemoveFromGarage();
  const history = useHistory();
  const confirmRobot = async (new_robot: RobotType) => {
    if (props.robot.id != new_robot.id) {
      await moveRobotToGarage({
        robotId: new_robot.id,
        garage_number: props.garage_number
      });
    }
    setMipPickModalVisible(false);
  };

  return (
    <>
      <DropdownAboutWrapper style={{ height: props.visible ? "207px" : 0, padding: props.visible ? "13px 19px 17px 19px" : 0 }}>
        <DropRobotImg>
          <img className="dropdownLight" src={light} alt="" />
          <img className="smallRobotIcon" src={props.image} onClick={() => history.push(`/garage/${props.garage_number}`)} alt="" />
        </DropRobotImg>
        <DropRobotText>
          <h4 className="mb-0">{props.name}</h4>
        </DropRobotText>
        <LineProgress currentExp={props.currentExp} needExp={props.needExp} level={props.level} />
        <div className="text-[#28efa8] text-[12px] mt-4">
          <span className="mr-[5px] hover:text-[#1fb680]" onClick={() => setMipPickModalVisible(true)}>
            SELECT MIP
          </span>
          |{" "}
          {removeRobotLoading ? (
            <CustomLoader margin="0" height="48px" width="100px" selectMip={true} />
          ) : (
            <span
              onClick={() => {
                removeRobotFromGarage(props.garage_number);
              }}
              className="ml-[5px] hover:text-[#1fb680]"
            >
              REMOVE
            </span>
          )}
        </div>
      </DropdownAboutWrapper>
      <ModalMIPPicker
        visible={mipPickModalVisible}
        processing={moveRobotLoading}
        onClose={() => setMipPickModalVisible(false)}
        onSelect={confirmRobot}
        highlightedId={props.robot.id}
        status={"NFT"}
      />
    </>
  );
};

const DropdownAboutWrapper = styled.div`
  width: 211px;
  height: 207px;
  background: #0b1014;
  box-shadow: -5.95434px 35.5042px 80px rgba(0, 0, 0, 0.13), -3.8593px 23.012px 46.8519px rgba(0, 0, 0, 0.0987037), -2.29353px 13.6757px 25.4815px rgba(0, 0, 0, 0.078963),
    -1.19087px 7.10083px 13px rgba(0, 0, 0, 0.065), -0.485169px 2.89293px 6.51852px rgba(0, 0, 0, 0.051037), -0.110266px 0.657485px 3.14815px rgba(0, 0, 0, 0.0312963);
  border-radius: 12px;
  position: absolute;
  top: 74px;
  left: 0;
  padding: 13px 19px 17px 19px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.3s all;
  overflow: hidden;
`;

const DropRobotImg = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: 7px;
  margin-bottom: 16px;

  .dropdownLight {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 60px;
  }

  .smallRobotIcon {
    position: absolute;
    transform: rotate(0deg);
    width: 80px;
    height: 80px;
    z-index: 2;
    border-radius: 50%;
  }
`;

const DropRobotText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #616d7a;
  }
`;
