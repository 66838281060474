import styled from "styled-components";
import lootHeaderBg from "../assets/img/backgroundSpinner.png";
import backspinner from "../assets/img/backSpinner.svg";
import arrow from "../assets/img/arrowBackSpinner.svg";
import { ReactComponent as Star } from "../assets/img/starSpinnerBG.svg";
import smallDiamond from "../assets/img/smallDiamond.svg";
import { TedButton } from "../components/common/Button/TedButton";
import { MultiBtn } from "../components/common/Button/MultiBtn";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAdminGetLootbox } from "../hooks/useAdminGetLootbox";
import { Roulette } from "../components/Roulette/Roulette";
import { queryClient } from "../utils/api";
import { useOpenLootbox } from "../hooks/useOpenLootbox";
import { CustomLoader } from "../components/common/Loader";
import { ModalMultiWinnerSpin } from "../components/common/Modals/ModalMultiWinnerSpin";
import { LootboxItem, ProfileUserSpins } from "../utils/commonTypes";
import { Modal } from "../components/common/Modal";
import { LootItem } from "../components/common/LootItem";
import { usePostBuyLootbox } from "../hooks/usePostBuyLootbox";

type SpinerPropsType = {
  balance: number;
};

export const Spiner = (props: SpinerPropsType) => {
  const params = useParams<{ lootboxId: string }>();
  const [wonItems, setWonItems] = useState<LootboxItem[] | null>(null);
  const { data: lootboxData, isLoading } = useAdminGetLootbox(+params.lootboxId);
  const [spinning, setSpinning] = useState<boolean>(false);
  const history = useHistory();
  const [showWonItems, setShowWonItems] = useState<boolean>(false);
  const { mutateAsync: openLootbox, data: wonItemsData, isLoading: openLootboxInProgress } = useOpenLootbox();
  const { mutate: buyLootbox } = usePostBuyLootbox();

  let price = lootboxData?.data.price || 0;

  const lootbox = lootboxData?.data;
  const lootboxItems = lootboxData?.data.items || [];
  const [activeQuantity, setactiveQuantity] = useState<number>(1);

  if (lootbox && !lootbox.canBeOpened) history.push("/loot");

  const open = async () => {
    const result = await openLootbox({ lootboxId: +params.lootboxId, quantity: activeQuantity });
    setWonItems(result.data.wonItems);
    queryClient.invalidateQueries("getAdminLootBox");
  };

  const startSpin = async () => {
    if (spinning) return;

    await open();
    setSpinning(true);
  };

  const quickOpen = async () => {
    await open();
    setShowWonItems(true);
  };

  const ownedQuantity = (lootbox?.users && lootbox.users[0] && lootbox?.users[0].quantity) || 0; // - quantityOpened;
  const openDisabled = !ownedQuantity || ownedQuantity < activeQuantity || openLootboxInProgress || spinning || showWonItems;

  return (
    <>
      <SpinerContainer>
        <TwistSpiner>
          <StarSpinner />
          <Spinner>
            {isLoading || !lootbox?.canBeOpened ? (
              <CustomLoader margin="100px 0" />
            ) : (
              <Roulette wonItems={wonItems} items={lootboxItems} spinning={spinning} setSpinning={setSpinning} setShowWonItems={setShowWonItems} />
            )}
          </Spinner>
          <BackSpinner onClick={() => history.push("/loot")}>
            <img src={arrow} alt="" />
            <img className="imgMiddle" src={backspinner} alt="" />
            <p>Case {params.lootboxId}</p>
          </BackSpinner>

          <ButtonGroup>
            <BigBtn>
              <TedButton size="big" width="250" color="yellow" onclick={() => startSpin()} disabled={openDisabled}>
                SPIN
              </TedButton>

              <TedButton size="big" width="250" color="blue" onclick={() => quickOpen()} disabled={openDisabled}>
                OPEN QUICKLY
              </TedButton>
            </BigBtn>
            <div style={{ textAlign: "center", color: "#fff", marginTop: "20px" }}>
              You own: {ownedQuantity} lootbox{ownedQuantity !== 1 ? "es" : ""}
            </div>
            <SmallBtn>
              {[1, 2, 3, 5, 7].map(quantity => (
                <div onClick={() => setactiveQuantity(quantity)} key={quantity}>
                  <MultiBtn active={activeQuantity === quantity}>{`x${quantity}`}</MultiBtn>
                </div>
              ))}
            </SmallBtn>
            <TedButton
              size="big"
              width="250"
              color="green"
              style={{ zIndex: 10 }}
              onclick={() => {
                buyLootbox({
                  lootboxId: +params.lootboxId,
                  quantity: activeQuantity
                });
              }}
              disabled={!lootbox?.onSale || props.balance < activeQuantity * (price || 0)}
            >
              <>
                BUY
                <CristalCount>
                  <span>{(price || 0) * activeQuantity} </span>
                  <img src={smallDiamond} alt="" />
                </CristalCount>
              </>
            </TedButton>
          </ButtonGroup>
        </TwistSpiner>
        <ItemSpinner>
          {isLoading ? (
            <CustomLoader margin="100px 0" />
          ) : (
            <SpinnerItems>
              {lootboxItems.map((item: LootboxItem) => (
                <SpinItemBG>
                  <LootItem key={item.id} item={item} settings={{ hideBottomBorder: true, hideBackground: true, height: "220px" }} />
                </SpinItemBG>
              ))}
            </SpinnerItems>
          )}
        </ItemSpinner>
        {showWonItems && wonItems && (
          <Modal
            visible={showWonItems}
            onClose={() => {
              queryClient.invalidateQueries("getProfile").then(() => {
                setShowWonItems(false);
              });
            }}
          >
            <ModalMultiWinnerSpin
              wonItems={wonItems}
              onclose={() => {
                setShowWonItems(false);
                queryClient.invalidateQueries("getProfile");
              }}
            />
          </Modal>
        )}
      </SpinerContainer>
    </>
  );
};

const SpinItemBG = styled.div`
  background: linear-gradient(90deg, #151b24 0%, #10161c 100%);
  border-radius: 12px;
  margin: 12px;
  position: relative;
`;

const SpinnerItems = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const BigBtn = styled.div`
  width: 526px;
  display: flex;
  align-self: center;
`;

const SmallBtn = styled.div`
  display: flex;
  align-self: center;
  margin: 20px 0 18px 0;

  button {
    margin: 0 4px;
    :hover {
      cursor: pointer;
    }
  }
`;

const CristalCount = styled.div`
  align-self: center;
  background: none !important;

  display: flex;
  align-items: center;
  margin-left: 10px;

  > span {
    font-size: 16px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;
    color: #0b0c0e;
  }

  > img {
    margin-right: 1px;
    margin-bottom: 1px;
    margin-left: 2px;
  }
`;

const BackSpinner = styled.div`
  display: flex;
  position: absolute;
  top: 48px;
  left: 48px;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .imgMiddle {
    margin: 0 6px 0 20px;
  }
`;

const Spinner = styled.div`
  z-index: 1;
  height: 450px;
  display: flex;
  align-items: center;
`;

const StarSpinner = styled(Star)`
  position: absolute;
  z-index: 0;

  top: 45px;
  left: 350px;
  @media (max-width: 1440px) {
    left: 203px;
  }
  @media (max-width: 1366px) {
    top: -16px;
    left: 160px;
  }
`;

const SpinerContainer = styled.div``;

const TwistSpiner = styled.div`
  background-image: url(${lootHeaderBg});
  height: 700px;
  position: relative;
`;

const ItemSpinner = styled.div``;
