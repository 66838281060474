import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import authHeader from "../utils/auth-header";
import { DailyYield } from "../utils/commonTypes";

function useGetDailyYields() {
  const { data, isLoading } = useQuery(["getDailyYields"], () =>
    axios
      .get<DailyYield[]>(`${apiRoute}/users/daily-yields`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );

  return { data, isLoading };
}

export { useGetDailyYields };
