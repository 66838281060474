import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import authHeader from "../utils/auth-header";
import { ItemTransaction } from "../utils/commonTypes";

function useGetItemTransactions(page: number) {
  const { data, isLoading } = useQuery(["getItemTransactions", page], () =>
    axios
      .get<ItemTransaction[]>(`${apiRoute}/users/item-transactions?page=${page}`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );

  return { data, isLoading };
}

export { useGetItemTransactions };
