module.exports = {
  prefix: [
    "Layer",
    "Thumbnail_Layer"
  ],
  model: [
    "OG",
    "Knight",
    "Sub",
    "Nurse",
    "Anchor",
    "Wings",
    "Bfly",
    "Samurai",
    "Bat",
    "Horns",
    "Nun",
    "Winter",
    "Paladin",
    "Ape",
    "Owl",
    "Puppet",
    "Lizard",
    "Castle",
    "Pilot",
    "Ranger",
    "Police",
    "Insect",
    "Tower",
    "Ninja",
    "Scientist",
    "-",

    "Spikes",
    "Decept",
    "Obot",
    "Ward",
    "Prot",
    "Joker",
    "Jaded",
    "Armour",
    "Edge",
    "Focus",
    "Border",
    "Met",
    "Lockout",
    "GBate",
    "Obsv",
    "iAGen",
    "Larm",
    "Hline",
    "Vizor",
    "Pharah",

    "Astronaut",
    "Academic",
    "Bunny",


  ],
  model_type: [
    "Driver",
    "Pet",
    "MIPHead"
  ],
  part: [
    "Eyebrows",
    "Mask",
    "Fin",
    "Shell",
    "Shell-Left",
    "Shell-Right",
    "Jetpack",
    "Thrusters",
    "Ears",
    "Hand_Left",
    "Hand_Right",
    "Head",
    "Hands",
    "-",
    "Fin-Left",
    "Fin-Right",
    "Thrusters-Left",
    "Thrusters-Right",
    "Ears-Left",
    "Ears-Right",
    "Kohai_body",
    "Senpai_body",
    "Kage_body",
    "Sage_body",


  ],
  material: [
    "Composite",
    "Metal",
    "Glow",
    "Crystal",
    "-",
  ],
  faction: [
    "Malaya",
    "Einar",
    "Coda",
    "Haruto",
    "Chiara",
    "Orphyx",
    "Raijin",
    "Prisma",
    "Pink_light",
    "Pink_Dark",
    "Pale_light",
    "Pale_Dark",
    "Fair_light",
    "Fair_Dark",
    "Tan_light",
    "Tan_Dark",
    "Dark",
    "Dark",
    "Hoshi",
    "Shiga",
    "Agoma",
    "Orochi",
    "Titan",
    "Enki",
    "Kuro",
    "-",
  ],
  sub_faction: [
    "Fanatic",
    "Adept",
    "Initiate",
    "-"
  ],
}