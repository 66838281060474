import { useState, useEffect } from "react";

const Countdown = (props: any) => {
  const min = Math.floor(props.seconds / 60);
  const sec = props.seconds - min * 60;

  useEffect(() => {
    if (props.seconds > 0)
      setTimeout(() => {
        props.updateSeconds(props.seconds - 1);
      }, 1000);
  }, []);

  useEffect(() => {
    if (props.seconds > 0)
      setTimeout(() => {
        props.updateSeconds(props.seconds - 1);
      }, 1000);
  }, [props.seconds]);

  return (
    <span>
      {min.toString().padStart(2, "0")}:{sec.toString().padStart(2, "0")}
    </span>
  );
};

export default Countdown;
