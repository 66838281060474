import { useEffect, useState, useContext } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useGetProfile } from "../../../hooks/useGetProfile";
import { AdminPage } from "../../../pages/Admin";
import { Faq } from "../../../pages/Faq";
import { Garage } from "../../../pages/Garage";
import { Landing } from "../../../pages/Landing";
import { Loot } from "../../../pages/Loot";
import { Market } from "../../../pages/Market";
import { Seasonpass } from "../../../pages/Seasonpass";
import { Top } from "../../../pages/Top";
import { Context } from "../../../utils/commonFunctions";
import { Footer } from "../../Footer";
import { LeftMenu, TopMenu } from "../../Menu";
import { PassBonuses } from "../../../pages/PassBonuses";
import { ProfileBonuses } from "../../../pages/ProfileBonuses";
import { Spiner } from "../../../pages/SpinerPage";
import { Modal } from "../Modal";
import { ModalAutorization } from "../Modals/ModalAutorization";
import { ModalForgetPassword } from "../Modals/ModalForgetPassword";
import { ModalNewPassword } from "../Modals/ModalNewPassword";
import { ModalProfileSettings } from "../Modals/ModalProfileSettings";
import { ModalSendTokens } from "../Modals/ModalSendTokens";
import { ModalSettingLanguage } from "../Modals/ModalSettingLanguage";
import { ModalSignIn } from "../Modals/ModalSignIn";
import { SnackBar } from "../SnackBar";
import { socket } from "../../../sockets";
import { GlobalContext, GlobalContextType } from "../../../App";
import { Progress } from "../../../pages/Progress/Progress";
import axios from "axios";
import { apiRoute } from "../../../utils/api";
import authHeader from "../../../utils/auth-header";
import { useQuery } from "react-query";
// import { connect } from "../../../metamask/balanceAndGaragePaidExample";

export const PageWrapper = () => {
  const location = useLocation();

  const { userInfo } = useContext(GlobalContext) as GlobalContextType;

  const [visibleSendTokens, setVisibleSendTokens] = useState<boolean>(false);
  const [visibleSignIn, setVisibleSignIn] = useState<boolean>(false);
  const [visibleProfileSettings, setVisibleProfileSettings] = useState<boolean>(false);
  const [visibleAutorization, setVisibleAutorization] = useState<boolean>(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState<boolean>(false);
  const [visibleForgetPassword, setVisibleForgetPassword] = useState<boolean>(false);

  const [visibleSettingsLanguage, setVisibleSettingsLanguage] = useState<boolean>(false);

  const [message, setMessage] = useState<string>("");
  const { data: lootboxCntData } = useQuery(["getLootboxCount"], () =>
    axios.get<{ lootbox_count: number }>(`${apiRoute}/users/lootbox-count`, { headers: authHeader() }).then(x => x.data)
  );

  const { data: dailyYieldBonusData } = useQuery(["getDailyYieldBonus"], () =>
    axios.get<{ yield_bonus_pct: number }>(`${apiRoute}/users/daily-yield-bonus`, { headers: authHeader() }).then(x => x.data)
  );

  console.log({ dailyYieldBonusData });

  return (
    <Switch>
      <Page>
        <Context.Provider value={{ setMessage }}>
          {!userInfo ? (
            <Landing />
          ) : (
            <>
              <Route path={"/admin"} component={AdminPage} />

              {!location.pathname.includes("/admin") && (
                <>
                  <TopMenu userInfo={userInfo} countCase={0} usedFreeBox={false} />
                  <LeftMenu isOpen={() => setVisibleSettingsLanguage(true)} />
                  <PageContent>
                    <Route exact path={"/"} render={() => <ProfileBonuses profile={userInfo} isOpen={() => setVisibleSettingsLanguage(true)} />} />
                    <Route path={"/garage/:garageNumber"} render={() => <Garage userInfo={userInfo} />} />
                    <Route path={"/loot/:lootboxId"} render={() => <Spiner balance={userInfo.balance || 0} />} />
                    <Route
                      exact
                      path={"/loot"}
                      render={() => (
                        <Loot balance={userInfo.balance || 0} countCase={lootboxCntData?.lootbox_count ?? 0} dailyYieldBonus={dailyYieldBonusData?.yield_bonus_pct ?? 0} />
                      )}
                    />
                    <Route
                      path={"/market/:marketPageNumber"}
                      render={() => (
                        <Market balance={userInfo.balance || 0} countCase={lootboxCntData?.lootbox_count ?? 0} dailyYieldBonus={dailyYieldBonusData?.yield_bonus_pct ?? 0} />
                      )}
                    />
                    <Route
                      exact
                      path={"/seasonpass"}
                      render={() => (
                        //<Seasonpass lvl={(data && +userInfo.level) || 0} />
                        <Seasonpass lvl={2} tickets={userInfo.tickets} />
                      )}
                    />
                    <Route
                      exact
                      path={"/progress"}
                      render={() => (
                        // <PassBonuses profile={userInfo.userInfo} />
                        <Progress />
                      )}
                    />
                    <Route path={"/faq"} component={Faq} />
                    <Route path={"/top"} component={Top} />
                  </PageContent>
                  <Footer />
                </>
              )}

              {visibleSendTokens && (
                <Modal visible={visibleSendTokens} onClose={() => setVisibleSendTokens(false)}>
                  <ModalSendTokens visible={visibleSendTokens} onClose={() => setVisibleSendTokens(false)} />
                </Modal>
              )}

              {visibleSignIn && (
                <Modal visible={visibleSignIn} onClose={() => setVisibleSignIn(false)}>
                  <ModalSignIn visible={visibleSignIn} onClose={() => setVisibleSignIn(false)} />
                </Modal>
              )}

              {visibleProfileSettings && (
                <Modal visible={visibleProfileSettings} onClose={() => setVisibleProfileSettings(false)}>
                  <ModalProfileSettings visible={visibleProfileSettings} onClose={() => setVisibleProfileSettings(false)} />
                </Modal>
              )}

              {visibleAutorization && (
                <Modal visible={visibleAutorization} onClose={() => setVisibleAutorization(false)}>
                  <ModalAutorization visible={visibleAutorization} onClose={() => setVisibleAutorization(false)} />
                </Modal>
              )}

              {visibleNewPassword && (
                <Modal visible={visibleNewPassword} onClose={() => setVisibleNewPassword(false)}>
                  <ModalNewPassword visible={visibleNewPassword} onClose={() => setVisibleNewPassword(false)} />
                </Modal>
              )}

              {visibleForgetPassword && (
                <Modal visible={visibleForgetPassword} onClose={() => setVisibleForgetPassword(false)}>
                  <ModalForgetPassword visible={visibleForgetPassword} onClose={() => setVisibleForgetPassword(false)} />
                </Modal>
              )}

              {visibleSettingsLanguage && (
                <ModalSettingLanguage
                  visible={visibleSettingsLanguage}
                  onClose={() => setVisibleSettingsLanguage(false)}
                  anim={Boolean(userInfo.animation)}
                  sound={Boolean(userInfo.sound)}
                  language={userInfo.language}
                />
              )}

              {message && <SnackBar text={message} onClose={() => setMessage("")} />}
            </>
          )}
        </Context.Provider>
      </Page>
    </Switch>
  );
};

const Page = styled.div``;

const PageContent = styled.div`
  padding-top: 90px;
  padding-left: 90px;
  width: 100%;
  box-sizing: border-box;
  min-height: calc(100vh - 170px);
  background: #0a0b0d;

  @media (max-width: 700px) {
    margin-top: 60px;
    margin-left: 0;
    width: 100%;
  }
`;
