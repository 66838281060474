import { useState, useEffect } from "react";

const ThreeDots = () => {
  const [dotCnt, setDotsCnt] = useState(0);
  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      setDotsCnt(current => (current % 4) + 1);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <span
      style={{
        width: "20px",
        display: "inline-block",
        position: "relative",
        left: "3px",
        textAlign: "left",
      }}
    >
      {new Array(dotCnt).join(".")}
    </span>
  );
};

export default ThreeDots;
