import axios from "axios";
import { QueryClient } from "react-query";

export const apiRoute = `${process.env.REACT_APP_API_BASE}${process.env.REACT_APP_API_ROUTE}`;
export const envGlob = process.env.NODE_ENV;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const imageRoute = `${process.env.REACT_APP_API_BASE}${process.env.REACT_APP_API_ROUTE}/images?image=`;

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  responseType: "json",
});
