import axios from "axios";
import { Dispatch, SetStateAction, useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";

type MutateDataType = {
  robotId: number;
  garage_number: number;
};

function useMoveRobotToGarage(setVisibleModal?: Dispatch<SetStateAction<boolean>>) {
  const context = useContext(Context);

  const { mutate, mutateAsync, isLoading, data } = useMutation(
    (data: MutateDataType) =>
      axios.post(`${apiRoute}/robots/move-to-garage`, data, {
        headers: authHeader()
      }),
    {
      onSuccess: () => {
        setVisibleModal?.(false);
        context?.setMessage("Changed active robot");
        queryClient
          .invalidateQueries("getProfile")
          .then(() => queryClient.invalidateQueries("getAllRobotsInProfile"))
          .then(() => queryClient.invalidateQueries("getParts"));
      }
    }
  );

  return { mutate, mutateAsync, isLoading, data };
}

export { useMoveRobotToGarage };
