import axios from "axios";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { RobotType } from "../utils/commonTypes";

type MintDataType = {
  modifiedRobot: RobotType;
  country: string;
  city: string;
};

type MinResType = {
  success: string;
  error?: string;
};

const useMintRobot = () =>
  useMutation(
    (data: MintDataType) =>
      axios.post<MinResType>(`${apiRoute}/remint`, data, {
        headers: authHeader(),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllRobotsInProfile");
      },
    },
  );

export default useMintRobot;
