import { Modal } from "../Modal";
import styled from "styled-components";
import { TedButton } from "../Button/TedButton";
import { Formik } from "formik";
import { useState } from "react";
import Select from "react-select";
import { useAdminPostSearchDetails } from "../../../hooks/useAdminPostSearchDetails";
import { useAdminPutManipylationDetail } from "../../../hooks/useAdminPutManipylationDetail";
import { useAdminGetLootbox } from "../../../hooks/useAdminGetLootbox";
import { customStylesForGarageSelect } from "../../../utils/commonFunctions";
import validationAddDetailSchema from "./validation/validationAddDetailSchema";

type PropsTypeWinSpin = {
  visible: boolean;
  onClose: () => void;
  addTokens: (amount: number, odds: number) => void;
  idBox: number;
};

export function ModalAdminLootboxAddTokens({
  idBox,
  visible,
  addTokens,
  onClose,
}: PropsTypeWinSpin) {
  const [valueInput, setValueInput] = useState<string>("");

  const { mutate: manipulateWithBtn } = useAdminPutManipylationDetail();

  return (
    <ModalWrapper visible={visible} onClose={() => onClose()}>
      <ModalContent>
        <h3>Add tokens</h3>

        <Formik
          initialValues={{
            amount: 0,
            odds: 0,
          }}
          validationSchema={validationAddDetailSchema}
          onSubmit={(values, { setSubmitting }) => {}}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <ItemModal>
              <form onSubmit={handleSubmit}>
                <TitleIpnut>Amount</TitleIpnut>
                <div>
                  <InputWrapperModalToken>
                    <InputModal
                      placeholder="Enter amount of tokens"
                      type="number"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={+values.amount}
                    />
                  </InputWrapperModalToken>
                </div>

                <TitleIpnut>Odds %</TitleIpnut>
                <InputWrapperModalToken>
                  <InputModal
                    placeholder="Enter odds %"
                    type="number"
                    name="odds"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={+values.odds}
                  />
                </InputWrapperModalToken>
                {errors.odds && touched.odds && errors.odds}

                <ModalButton>
                  <TedButton
                    type="submit"
                    disabled={isSubmitting}
                    size="modal"
                    width="338"
                    color="green"
                    onclick={() => {
                      addTokens(values.amount, values.odds);
                      onClose();
                    }}
                  >
                    Confirm
                  </TedButton>
                </ModalButton>
              </form>
            </ItemModal>
          )}
        </Formik>
      </ModalContent>
    </ModalWrapper>
  );
}

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input`
  width: 317px;
  height: 46px;
  background: #161d24;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #25313d;
  }
  ::-moz-placeholder {
    color: #25313d;
  }
`;

const ModalWrapper = styled(Modal)`
  height: 550px;
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 34px;
`;

const ItemModal = styled.div`
  width: 345px;
  position: relative;
  text-align: left;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  height: 350px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
