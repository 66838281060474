import detectEthereumProvider from "@metamask/detect-provider";
import { Contract } from "web3-eth-contract";
import { AbiItem } from "web3-utils";
import Web3 from "web3";
import MIPContractConfig from "./config/MIPContractConfig.json";
import mechIdentityProtocolABI from "./config/mechIdentityProtocolABI.json";
import mipTicketsABI from "./config/mipTicketsABI.json";
import authHeader from "../utils/auth-header";
import { apiRoute } from "../utils/api";
let MIPContract: any;
let TicketContract: any;

const setup = async () => {
  const ethereum: any = await detectEthereumProvider();
  const metamaskIsInstalled = ethereum && ethereum["isMetaMask"];

  if (metamaskIsInstalled) {
    const web3 = new Web3(ethereum);
    web3.setProvider(ethereum);
    MIPContract = new web3.eth.Contract(mechIdentityProtocolABI as AbiItem[], process.env.REACT_APP_MIP_CONTRACT_ADDRESS);
    TicketContract = new web3.eth.Contract(mipTicketsABI as AbiItem[], process.env.REACT_APP_TICKET_CONTRACT_ADDRESS);
  }
};

setup();

const isGarageOperationPaid = async (account: string) => {
  const userInfo = await MIPContract.methods.userInfo(account).call({ from: account });
  return userInfo?.garageOperationPaid;
};

const payGarageOperation = async (account: string) => {
  try {
    console.log("paying garage opeartion");
    const prices = await MIPContract.methods.prices().call();
    console.log({ prices });
    const estimatedGas = await MIPContract.methods.payGarageOperation().estimateGas({
      from: account,
      value: prices.garagePrice
    });

    console.log({ prices, estimatedGas });

    await MIPContract.methods.payGarageOperation().send({
      from: account,
      value: prices.garagePrice,
      gas: Math.ceil(estimatedGas * 1.1)
    });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, error, message: (error as any).code == -32000 ? "Insufficient funds" : null };
  }
};

const redeemTicket = async (account: string, ticket_id: string, ticket_amount: number = 1) => {
  try {
    console.log(`redeeming ticket id: ${ticket_id}`);
    const estimatedGas = await MIPContract.methods.redeemTicket(ticket_id, ticket_amount).estimateGas({
      from: account
    });

    const tx = await MIPContract.methods.redeemTicket(ticket_id, ticket_amount).send({
      from: account,
      gas: Math.ceil(estimatedGas * 1.1)
    });

    return { success: true, tx };
  } catch (error) {
    console.log(error);
    return { success: false, error, message: (error as any).code == -32000 ? "Insufficient funds" : null };
  }
};

const claimMIP = async (account: string, mipType: number, quantity: number = 1) => {
  try {
    const estimatedGas = await MIPContract.methods.claimMIP(mipType, quantity).estimateGas({
      from: account
    });

    const tx = await MIPContract.methods.claimMIP(mipType, quantity).send({
      from: account,
      gas: Math.ceil(estimatedGas * 1.1)
    });

    return { success: true, tx };
  } catch (error) {
    console.log(error);
    return { success: false, error, message: (error as any).code == -32000 ? "Insufficient funds" : null };
  }
};

const getTicketBalance = async (account: string, ticket_id: string) => {
  return TicketContract.methods.balanceOf(account, ticket_id).call();
};

const getTicketsBalance = async (account: string, ticket_ids: number[]) => {
  const balances = await TicketContract.methods.balanceOfBatch(Array(ticket_ids.length).fill(account), ticket_ids).call();
  const result: any = {};
  balances.forEach((balance: any, i: number) => (result[ticket_ids[i]] = balance));
  return result;
};

const getRemainingClaimCount = async (account: string) => {
  const userInfo = await MIPContract.methods.userInfo(account).call();
  const contractInfo = await MIPContract.methods.contractInfo().call();
  return Number(contractInfo.bodyMaxDirectClaims) - Number(userInfo.bodyMipsDirectlyClaimed);
};

export { MIPContract, isGarageOperationPaid, payGarageOperation, redeemTicket, getTicketBalance, getTicketsBalance, claimMIP, getRemainingClaimCount };
