import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";
import { WonItemsData } from "../utils/commonTypes";

function useOpenLootbox() {
  const context = useContext(Context);

  const { mutate, isLoading, data, mutateAsync } = useMutation(
    (data: { lootboxId: number; quantity: number }) =>
      axios.post<WonItemsData>(
        `${apiRoute}/lootboxes/open`,
        { lootboxId: data.lootboxId, amount: data.quantity },
        {
          headers: authHeader()
        }
      ),
    {
      onSuccess: res => {
        if (data?.data.error) {
          context?.setMessage("Error. Sorry, please, try later");
          queryClient.invalidateQueries("getProfile");
        }
      }
    }
  );

  return { mutate, isLoading, data, mutateAsync };
}

export { useOpenLootbox };
