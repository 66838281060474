import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect, useRef, ChangeEvent } from "react";
import { ModalAdminLootboxAddTokens } from "../common/Modals/ModalAdminLootboxAddTokens";
import { useAdminGetLootbox } from "../../hooks/useAdminGetLootbox";
import { CustomLoader } from "../common/Loader";
import { usePostUploadLootboxImage } from "../../hooks/usePostUploadLootboxImage";
import { imageRoute } from "../../utils/api";
import { Modal } from "../common/Modal";
import { TedButton } from "../common/Button/TedButton";
import { useAdminGetAllImageLootBoxes } from "../../hooks/useAdminGetAllImageLootBoxes";
import { useAdminUpsertLootboxItems } from "../../hooks/useAdminUpsertLootboxItems";
import { checkImageSrc } from "../../utils/commonFunctions";
import { PartType, LootboxItem } from "../../utils/commonTypes";
import { Filters } from "../common/Filters/Filters";
import { AdminLootItem } from "./AdminLootItem";
import mipCoin from "../../assets/img/coin200x200.png";

export const AdminEditCase = () => {
  const { data: galleryLootBox, isLoading } = useAdminGetAllImageLootBoxes();
  const [parts, setParts] = useState<PartType[]>([]);
  const [lootboxItems, setLootboxItems] = useState<LootboxItem[]>([]);
  const [lootboxImage, setLooboxImage] = useState<string | null>(null);

  const history = useHistory();

  const params = useParams<{ caseId: string; pageId: string }>();
  const { mutate: uploadImage } = usePostUploadLootboxImage();

  const { data: lootboxData, isLoading: loadingOneCase } = useAdminGetLootbox(+params.caseId);

  const fileRef = useRef<HTMLInputElement>(null);

  const fileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) setLooboxImage(URL.createObjectURL(event.target.files[0]));
    else setLooboxImage(null);
  };

  const addTokens = (amount: number, odds: number) => {
    const lootboxItem = {
      odds,
      type: "tokens",
      token_amount: amount
    };
    setLootboxItems([...lootboxItems, lootboxItem]);

    console.log("addTokens", lootboxItem);
  };

  const removeItem = (index: number) => {
    setLootboxItems(lootboxItems.filter((item, idx) => idx != index));
  };

  useEffect(() => {
    if (lootboxData) setLootboxItems(lootboxData.data.items);
  }, [lootboxData]);

  const { mutate: upsertItems } = useAdminUpsertLootboxItems();

  const [visibleAddDetail, setVisibleAddDetail] = useState<boolean>(false);
  const [visibleAddImage, setVisibleAddImage] = useState<boolean>(false);

  const arrImgId = [0];

  const [activeCaseImg, setActiveCaseImg] = useState<number[]>(arrImgId);

  const lootbox = lootboxData?.data;

  const isAdded = (id: number) => {
    return lootboxItems.findIndex(p => p.part?.id == id) !== -1;
  };

  const saveChanges = () => {
    if (lootboxItems.length > 0) {
      let totalOdds = 0;
      for (let item of lootboxItems) {
        if (item.odds == 0) return alert("Odds for an item must be greater than 0.");
        totalOdds += item.odds;
      }
      if (totalOdds != 100) return alert("Odds must amount to 100.");
    } else return alert("You must add at least one item.");

    let lootboxItemsToUpdate = lootboxItems.map(x => Object.assign({}, x));
    for (let item of lootboxItemsToUpdate) delete item.part;
    upsertItems({
      lootboxId: +params.caseId,
      items: lootboxItemsToUpdate
    });

    if (fileRef.current?.files?.length) {
      const formData = new FormData();
      //@ts-ignore
      formData.append("image", fileRef.current?.files[0]);
      formData.append("lootboxId", params.caseId);
      uploadImage(formData);
    }
  };

  const changeField = (field: string, idx: number, value: number) => {
    let newLootboxitems: any = [...lootboxItems];
    newLootboxitems[idx][field] = value;
    setLootboxItems(newLootboxitems);
  };

  const togglePart = (part: PartType, operation: string) => {
    if (operation === "add") {
      const lootboxItem = {
        odds: 0,
        type: "part",
        partId: part.id,
        part: part,
        substitute_tokens: part.price || 0
      };
      setLootboxItems([...lootboxItems, lootboxItem]);
    } else {
      setLootboxItems(lootboxItems.filter(p => p.part?.id !== part.id));
    }
  };

  const pushOrDellActiveLootBox = (id: number) => {
    if (!activeCaseImg.filter(item => item === id).length) {
      const array3 = activeCaseImg.filter(i => i !== 0).concat([id]);
      setActiveCaseImg(array3);
    } else {
      const array4 = activeCaseImg.filter(item => item !== id && item !== 0);
      setActiveCaseImg(array4);
    }
  };

  return (
    <AdminContentWrapper>
      <BackBtn onClick={() => history.push(`/admin/lootboxes/${params.pageId}`)}>&lt; BACK</BackBtn>
      {loadingOneCase ? (
        <CustomLoader margin="100px 0" />
      ) : (
        <>
          <TableWrapper>
            <ActionButton onClick={saveChanges} style={{ marginLeft: "10px" }}>
              Save changes
            </ActionButton>
            <ActionButton onClick={() => setVisibleAddDetail(true)}>Add tokens</ActionButton>
            <MainColumn>
              <input ref={fileRef} onChange={fileChange} type="file" style={{ display: "none" }} />
              {!lootboxImage && !lootbox?.image ? (
                <ImgDiv onClick={() => fileRef.current?.click()}>Add Image</ImgDiv>
              ) : (
                <ImgDiv style={{ border: "none" }} onClick={() => fileRef.current?.click()}>
                  <img src={lootboxImage ?? `${imageRoute}${encodeURIComponent("lootboxes/" + lootbox!.image)}`} alt="" />
                </ImgDiv>
              )}
              <TitleCase>
                <span>{lootbox?.name}</span>
              </TitleCase>
            </MainColumn>
            {lootboxItems?.length === 0 && <ColumnNotDetails>No items</ColumnNotDetails>}
            {lootboxItems.map((item, idx) => (
              <Column key={idx}>
                <ImgDetail>
                  <img src={item.part ? `${imageRoute}${checkImageSrc(item.part!.images, "Thumbnail_Layer", item.part.section)}` : mipCoin} alt="" />
                </ImgDetail>
                <div style={{ width: "300px", textAlign: "left", display: "inline-block" }}>{item.type == "part" ? item.part!.name : "Tokens"}</div>
                <NumberWrapper>
                  Odds:
                  <NumInput
                    type="number"
                    min="1"
                    max="100"
                    value={item.odds || 0}
                    onChange={event => {
                      changeField("odds", idx, +event.target.value);
                    }}
                  />
                </NumberWrapper>
                <NumberWrapper style={{ justifyContent: "space-between" }}>
                  <div>{item.type == "part" ? "Substitute tokens" : "Amount"}:</div>
                  <NumInput
                    type="number"
                    min="1"
                    value={item.type == "part" ? item.substitute_tokens : item.token_amount || 0}
                    onChange={event => {
                      changeField(item.type == "part" ? "substitute_tokens" : "token_amount", idx, +event.target.value);
                    }}
                  />
                </NumberWrapper>
                <IdCase style={{ visibility: item.type == "part" ? "visible" : "hidden" }}>ID: {item.part?.id}</IdCase>
                <div>
                  <RemoveBtn onClick={() => removeItem(idx)}>X</RemoveBtn>
                </div>
              </Column>
            ))}
          </TableWrapper>
        </>
      )}
      <Filters onPartsUpdate={setParts}>
        <Parts>
          {parts?.map(part => (
            <AdminLootItem
              key={part.id}
              part={part}
              imgSrc={`${imageRoute}${checkImageSrc(part.images, "Thumbnail_Layer", part.section)}`}
              isAdded={isAdded(part.id)}
              manipulate={togglePart}
            />
          ))}
        </Parts>
      </Filters>
      {visibleAddDetail && <ModalAdminLootboxAddTokens idBox={+params.caseId} visible={visibleAddDetail} addTokens={addTokens} onClose={() => setVisibleAddDetail(false)} />}
      {visibleAddImage && (
        <Modal visible={visibleAddImage} onClose={() => setVisibleAddImage(false)}>
          <ModalContent>
            <h3>Select Image</h3>
            {isLoading ? (
              <CustomLoader margin="100px 0" />
            ) : (
              <>
                <GalleryLootBox>
                  {galleryLootBox?.data.map((item, idx) => (
                    <RobotWrapper active={!!activeCaseImg?.filter(it => it === item.id).length} key={idx} onClick={() => pushOrDellActiveLootBox(item.id)}>
                      <ItemModal>
                        <RobotModal src={`${imageRoute}${item.key}`} alt="" />
                      </ItemModal>
                    </RobotWrapper>
                  ))}
                </GalleryLootBox>
                <ModalButton>
                  <TedButton size="modal" width="260" color="green" onclick={() => {}}>
                    Confirm
                  </TedButton>
                </ModalButton>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </AdminContentWrapper>
  );
};

const BackBtn = styled.div`
  color: white;
  background: black;
  padding: 13px 32px;
  border-radius: 20px;
  position: absolute;
  top: 150px;
  left: 150px;
  :hover {
    cursor: pointer;
  }
`;

const ColumnNotDetails = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 18px;
  background: #1c252e;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  color: white;
`;

const TitleCase = styled.div`
  margin-left: 20px;
  > span {
    font-size: 26px;
    letter-spacing: 2px;
    font-weight: bold;
  }
`;

const ImgDetail = styled.div`
  > img {
    height: 60px;
  }
`;

const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NumInput = styled.input`
  background: none;
  color: white;
  margin-left: 10px;
  text-align: center;
  width: 35px;
  outline: none;
  border: 1px solid #050b1c;
  border-radius: 5px;
  padding: 5px;
  box-shadow: none;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const IdCase = styled.div`
  align-items: center;
  > div {
    margin-left: 60px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      cursor: pointer;
      background: red;
      border-radius: 50%;
    }
  }
`;

const ImgDiv = styled.div`
  border: 1px solid #5a5a5a;
  border-style: dashed;
  border-radius: 5px;
  min-width: 200px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  > img {
    height: 275px;
  }
  :hover {
    cursor: pointer;
  }
`;

const MainColumn = styled.div`
  margin-top: 18px;
  display: flex;
  width: 100%;
  min-height: 100px;
  padding: 10px 0px;
  color: white;
  align-items: center;
  justify-content: center;
`;

const Column = styled.div`
  margin-top: 18px;
  background: #1c252e;
  border-radius: 5px;
  display: flex;
  width: calc(100% - 100px);
  height: 60px;
  padding: 0 50px;
  align-items: center;
  justify-content: center;
  color: white;
  > div {
    display: flex;
    justify-content: center;
    width: 180px;
  }
`;
const TableWrapper = styled.div`
  width: 80%;
  margin-top: 30px;
  align-self: start;
`;

const ActionButton = styled.div`
  float: right;
  margin-bottom: 16px;
  color: black;
  broder: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #29efa8 20%, #00a355 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  place-self: flex-end;
  :hover {
    cursor: pointer;
  }
`;

const AdminContentWrapper = styled.div`
  margin: -50px 0 -50px 0;
  min-height: calc(100vh - 90px);
  background: #050b1c;
  padding-top: 130px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
  color: #ffffff;

  align-items: center;
  width: 100%;
`;

const GalleryLootBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 350px;
`;
const RobotWrapper = styled.div<{
  active: boolean;
}>`
  margin: 6px;
  background: #0b1014;
  border-radius: 12px;
  width: 223px;
  height: 266px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  border: 2px solid #0b1014;

  :hover {
    cursor: pointer;
  }

  ${props =>
    props.active
      ? `background: linear-gradient(90deg, #151b24 0%, #10161c 100%);
  border: 2px solid #29efa8;`
      : ""}
`;

const ModalButton = styled.div`
  display: flex;
  margin: 28px 0;
`;

const RobotModal = styled.img`
  z-index: 5;
  width: 196px;
`;

const ItemModal = styled.div`
  width: 183px;
  height: 183px;
  position: relative;
  display: flex;
  color: #ffffff;

  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80wh;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;

const Parts = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 446px) {
    margin: 20px 15px;
  }
`;

const RemoveBtn = styled.div`
  transform: scaleX(1.4);
  cursor: pointer;
  color: #896a6a;
  border: 1px solid #896a6a;
  padding: 4px;
  border-radius: 8px;
  padding-bottom: 3px;

  &:hover {
    color: red;
    border-color: red;
  }
`;
