import styled from "styled-components";
import freeSeasonPass from "../../assets/img/seasonpassticket5.png";
import premiumSeasonPass from "../../assets/img/premiumseasonpass.png";
import mipFrame from "../../assets/img/Frame.png";
import arrowLeft from "../../assets/img/arrowLeft.png";
import arrowRight from "../../assets/img/arrowRight.png";
import levelCardBorder from "../../assets/img/levelCardBorder.png";
import completedCheckmark from "../../assets/img/completed.png";
import lock from "../../assets/img/lock.png";
import mipCoin from "../../assets/img/coin200x200.png";
import "./Progress.css";
import { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Level, Lootbox, PartType, RobotType } from "../../utils/commonTypes";
import { apiRoute, imageRoute } from "../../utils/api";
import authHeader from "../../utils/auth-header";
import starBg from "../../assets/img/starBg.png";
import starBgOverlay from "../../assets/img/starBgOverlay.png";
import { checkImageSrc } from "../../utils/commonFunctions";
import { Garage as GarageType } from "../../utils/commonTypes";
import { TedButton } from "../../components/common/Button/TedButton";
import { ModalMIPPicker } from "../../components/common/Modals/ModalMIPPicker";
import { useMoveRobotToGarage } from "../../hooks/useMoveRobotToGarage";

export const GarageProgress = (props: { garage: GarageType; pass: string }) => {
  const { garage, pass } = props;
  const robot = garage.robot;
  const garage_level = pass == "free" ? garage.free_pass_level : garage.premium_pass_level;
  const garage_xp = pass == "free" ? garage.free_pass_xp : garage.premium_pass_xp;
  const { data: levels } = useQuery(["getLevels"], () =>
    axios
      .get<Level[]>(`${apiRoute}/admin/levels`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );
  const [scrolledLevels, setScrolledLevels] = useState<number>(garage_level - 1);
  const nextLevel = levels?.find(x => x.level == garage_level + 1);
  const levelProgress = nextLevel ? (garage_xp / nextLevel.xp_required!) * 100 : -1;
  const [mipPickModalVisible, setMipPickModalVisible] = useState<boolean>(false);
  const { mutateAsync: moveRobotToGarage, isLoading: moveRobotLoading } = useMoveRobotToGarage();
  const confirmRobot = async (new_robot: RobotType) => {
    if (robot?.id != new_robot.id) {
      await moveRobotToGarage({
        robotId: new_robot.id,
        garage_number: garage.number
      });
    }
    setMipPickModalVisible(false);
  };
  return (
    <>
      <GarageLine>
        <Garage>
          {robot ? (
            <p>{robot.name}</p>
          ) : (
            <div className="mt-[20px]">
              <TedButton onclick={() => setMipPickModalVisible(true)} size="small" color="green" width="140" active={false}>
                Select MIP
              </TedButton>
            </div>
          )}
          <ProgressLvl>
            <LevelCircle>
              <p>{garage_level}</p>
            </LevelCircle>
            <ProgressElem>
              <LineProgress progress={levelProgress} />
            </ProgressElem>
          </ProgressLvl>
          <h1>{garage_xp} exp</h1>
          <img className={`${!robot?.image ? "!w-[77%]" : ""}`} src={robot?.image ?? mipFrame} alt="" />
        </Garage>
        {levels && (
          <>
            <img src={arrowLeft} onClick={() => scrolledLevels != -1 && setScrolledLevels(prev => prev - 1)} className="ml-[35px] cursor-pointer hover:opacity-[0.5]" />
            <div className="overflow-hidden flex items-center mx-[35px] w-[935px] relative z-0">
              <div className="passShadowBg w-[200px] h-[300px] absolute top-[50px] left-[71px]"></div>
              <LevelCard className="levelCard !ml-0 mt-[20px]" placeholder={true}>
                <h1 className="text-white mt-5 font-[600]">{pass == "free" ? "Free" : "Premium"} season pass</h1>
                <img src={pass == "free" ? freeSeasonPass : premiumSeasonPass} style={{ position: "absolute", bottom: "-90px" }} />
                {pass == "premium" && <img src={starBg} className="centered-absolute z-[-1] top-[73%]" />}
              </LevelCard>
              <ScrollTrack className="ml-[4px]" style={{ left: `${scrolledLevels * -1 * 208 + 100}px` }}>
                {levels.map((level, idx) => (
                  <div className={`flex flex-col relative ${!robot ? " opacity-80 brightness-50" : ""}`}>
                    <LevelCard
                      className="levelCard"
                      completed={level.level <= garage_level}
                      level={level}
                      pass={pass}
                      style={{
                        bottom: idx - 1 == scrolledLevels ? "30px" : 0
                      }}
                    />
                    <ProgressLvl className="absolute bottom-[-40px] left-[-90px]">
                      <ProgressElem className={`ml-[8.5px] !h-[4px] !w-[155px] ${level.level == 1 || scrolledLevels > level.level - 1 ? "invisible" : ""}`}>
                        <LineProgress
                          progress={level.level <= garage_level ? 100 : garage_level + 1 == level.level ? (garage_xp / level.xp_required!) * 100 : 0}
                          className="!h-[4px]"
                        />
                      </ProgressElem>
                      <span className={`text-[24px] text-white mx-[8px] ${scrolledLevels > level.level ? "invisible" : ""}`}>{level.level}</span>
                      <LevelCircle className={`!w-[16px] !h-[16px] ${scrolledLevels > level.level ? "invisible" : ""}`}></LevelCircle>
                    </ProgressLvl>
                  </div>
                ))}
              </ScrollTrack>
              <div className="absolute bottom-0 left-[42%]"></div>
            </div>
            <img src={arrowRight} onClick={() => scrolledLevels < levels.length - 2 && setScrolledLevels(prev => prev + 1)} className="cursor-pointer hover:opacity-[0.5]" />
          </>
        )}
      </GarageLine>
      <ModalMIPPicker
        visible={mipPickModalVisible}
        processing={moveRobotLoading}
        onClose={() => setMipPickModalVisible(false)}
        onSelect={confirmRobot}
        highlightedId={robot?.id}
        status="NFT"
      />
    </>
  );
};

const LevelCard = (props: any) => {
  const { level, pass } = props;
  const reward_type = level?.[`${pass}_reward_type`];
  const part: PartType | null = level?.[`${pass}_part`];
  const lootbox: Lootbox | null = level?.[`${pass}_lootbox`];
  const justifyContent = level ? "center" : "start";
  return (
    <LevelCardBase style={{ ...props.style, justifyContent, zIndex: 0 }} className={props.className}>
      {!props.placeholder && (
        <>
          <img src={props.completed ? completedCheckmark : lock} className="absolute top-[20px] left-[148px]" />
          <img className="lvlCardBorder" src={levelCardBorder} />
        </>
      )}
      {reward_type == "tokens" && (
        <>
          <div className="text-[#FFD63D] text-[24px] flex z-10">
            {level[`${pass}_token_reward`]} <img src={mipCoin} className="w-[18px] h-[18px] ml-[6px] top-[3px]" />
          </div>
          <span className="absolute left-[20px] bottom-[6.67%] text-[#616D7A] text-[12px]">Tokens</span>
          <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            <div className="w-[97px] h-[97px] bg-[#2C3A46] blur-[50px]"></div>
          </div>
        </>
      )}
      {(part || lootbox) && (
        <>
          <div className="levelCardShadow"></div>
          <img src={starBg} className="centered-absolute z-[-1]" />
          <img src={starBgOverlay} className="centered-absolute z-[-1]" />
          <div>
            {part ? (
              <img className="w-full" src={`${imageRoute}${checkImageSrc(part.images, "Thumbnail_Layer", part.section)}`} />
            ) : (
              <img className="w-full" src={`${imageRoute}lootboxes/${lootbox!.image}`} alt="" />
            )}
          </div>
          <div className="flex absolute bottom-[6.67%] left-[20px] flex-col">
            <span className="text-[16px] text-white">{part != null ? part.name : lootbox!.name}</span>
            <span className="text-[12px] text-[#616D7A]">{part != null ? part.category : lootbox!.price}</span>
          </div>
        </>
      )}
      {props.children}
    </LevelCardBase>
  );
};

const ScrollTrack = styled.div`
  display: flex;
  height: 400px;
  align-items: center;
  position: relative;
  transition: 0.3s all;
  z-index: -1;
`;

const GarageLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const Garage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 226px;
  height: 300px;
  flex-shrink: 0;
  background: #0b1014;
  border-radius: 12px;
  color: #ffffff;
  overflow: hidden;
  filter: drop-shadow(0px 14px 9px black);

  > h1 {
    font-size: 12px;
    line-height: 14px;
    margin-top: 6px;
  }

  > p {
    font-size: 16px;
    line-height: 19px;
    margin: 20px 0 4px 0;
  }

  > span {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }

  > img {
    margin-top: 15px;
    width: 226px;
  }
`;

const ProgressLvl = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const LevelCardBase = styled.div`
  width: 200px;
  height: 300px;
  overflow: hidden;
  background: linear-gradient(90deg, #151b24 0%, #10161c 100%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  transition: left 0.3s, bottom 0.2s;

  img.lvlCardBorder {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const LevelCircle = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: #29efa8;
  > p {
    font-size: 14px;
    margin-top: 4px;
    line-height: 17px;
    color: #00301b;
  }
`;

const ProgressElem = styled.div`
  height: 6px;
  z-index: 1;
  background: #1f2933;
  border-radius: 6px;
  width: 100px;
  position: relative;
`;

const LineProgress = styled.div<{
  progress: number;
}>`
  height: 6px;
  background: #29efa8;
  border-radius: 6px;
  margin-left: -3px;
  width: ${props => props.progress}%;
`;
