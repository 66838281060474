import styled from "styled-components";
import lootHeaderBg from "../assets/img/backgroundMarket.png";
import { Achieve } from "../components/common/Achieve";
import { MarketItem } from "../components/common/MarketItem";
import { ReactComponent as RectangleIcon } from "../assets/img/tabRectangle.svg";
import { useState } from "react";
import { checkImageSrc, checkSingleParamInSparePart, checkTier, customStylesForGarageSelect } from "../utils/commonFunctions";
import { useGetParamsForDetailsFilter } from "../hooks/useGetParamsForDetailsFilter";
import { useParams } from "react-router-dom";
import { imageRoute } from "../utils/api";
import { ParamsForDetailsFilter } from "../utils/commonTypes";
import { Filters } from "../components/common/Filters/Filters";
import diamondImg from "../assets/img/smallDiamond.svg";
import boxImg from "../assets/img/smallBox.svg";
import starImg from "../assets/img/star.svg";

type MarketPropsType = {
  balance: number;
  countCase: number;
  dailyYieldBonus: number;
};

type AchieveType = {
  img: string;
  imgWidth: string;
  text: string;
  description: string;
};

const rarityTabs = [
  {
    text: "All",
    value: "",
    img: false,
    color: "none"
  },
  {
    text: "Common",
    value: "Gray",
    img: true,
    color: "#6A6A6A"
  },
  {
    text: "Uncommon",
    value: "Green",
    img: true,
    color: "#29EFA8"
  },
  {
    text: "Rare",
    value: "Blue",
    img: true,
    color: "#319CFF"
  },
  {
    text: "Epic",
    value: "Purple",
    img: true,
    color: "#6829EF"
  },
  {
    text: "Legendary",
    value: "Yellow",
    img: true,
    color: "#FFAD0F"
  },
  {
    text: "Immortal",
    value: "Red",
    img: true,
    color: "#FF3030"
  }
];

export const Market = (props: MarketPropsType) => {
  const achieves: AchieveType[] = [
    {
      img: diamondImg,
      imgWidth: "32px",
      text: props.balance.toString(),
      description: "Tokens"
    },
    {
      img: boxImg,
      imgWidth: "30px",
      text: props.countCase.toString(),
      description: "Lootboxes"
    },
    {
      img: starImg,
      imgWidth: "26px",
      text: `+${props.dailyYieldBonus.toString()}%`,
      description: "Bonus"
    }
  ];
  const params = useParams<{ marketPageNumber: string }>();

  const [activeTab, setActiveTab] = useState<string>("All");
  const [switchers, setSwitchers] = useState<boolean>(false);
  const [parts, setParts] = useState<any[]>([]);

  const { data: filterParams } = useGetParamsForDetailsFilter();

  const [partSelectValue, setPartSelectValue] = useState<string>("");
  const [factionSelectValue, setFactionSelectValue] = useState<string>("");
  const [searchStr, setSearchStr] = useState<string>("");

  const checkFilterParams = (str: string) => {
    const emptyFilter = {
      value: "",
      label: `No ${str} filter`
    };
    const filterParamsArr = filterParams?.data[str as keyof ParamsForDetailsFilter]?.map(item => {
      return {
        value: item,
        label: item
      };
    });
    return filterParamsArr ? [emptyFilter, ...filterParamsArr] : [emptyFilter];
  };

  const [rarDetails, setRarDetails] = useState<string>("");

  const handleClick = (newTab: string, rar: string) => {
    setActiveTab(newTab);
    setRarDetails(rar);
  };

  return (
    <MarketContainer>
      <MarketHeader>
        <MarketTextAndAchieve>
          <MarketText>
            <h4>Market</h4>
            <p>But because those who do not know how to pursue pleasure rationally encounter consequences that</p>
          </MarketText>
          <MarketAchieves>
            {achieves.map((ach, idx) => (
              <Achieve key={idx} img={ach.img} text={ach.text} descr={ach.description} imgWidth={ach.imgWidth} />
            ))}
          </MarketAchieves>
        </MarketTextAndAchieve>
        <MarketTabsAndSort>
          <MarketTabs>
            {rarityTabs.map(tab => (
              <MarketTab key={tab.value} onClick={() => handleClick(tab.value, tab.value)} active={activeTab === tab.value} color={tab.color}>
                {tab.img && <RectangleIcon />}
                <span>{tab.text}</span>
              </MarketTab>
            ))}
          </MarketTabs>
        </MarketTabsAndSort>
      </MarketHeader>
      <Filters onPartsUpdate={setParts}>
        <MarketItems>
          {parts?.map((invItem: any) => (
            <MarketItem
              key={invItem.id}
              idDetail={invItem.id}
              name={invItem.name}
              part={checkSingleParamInSparePart("part", invItem.partparams)}
              price={invItem.price || 0}
              imgSrc={`${imageRoute}${checkImageSrc(invItem.images, "Thumbnail_Layer", invItem.section)}`}
              tier={checkTier(invItem.partparams)}
              disabled={!invItem.onSale || invItem.in_stock == 0 || (!!invItem.price && props.balance < invItem.price)}
              count={invItem.in_stock}
            />
          ))}
        </MarketItems>
      </Filters>
    </MarketContainer>
  );
};

const MarketTabsAndSort = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;

const MarketTabs = styled.div`
  display: flex;
`;

const MarketTab = styled.div<{ active: boolean; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 0;
  width: 200px;
  opacity: ${props => (props.active ? 1 : 0.5)};

  ${props =>
    props.active &&
    `background: #0A0B0D;
  border-radius: 12px 12px 0px 0px;`}

  :hover {
    cursor: pointer;
  }

  > svg {
    margin-right: 6px;
    fill: ${props => props.color || ""};
  }

  > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 1440px) {
    width: 170px;
  }
  @media (max-width: 1440px) {
    width: 155px;
  }
`;

const MarketItems = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;
`;

const MarketContainer = styled.div`
  color: #fff;
`;

const MarketHeader = styled.div`
  height: 251px;
  box-sizing: border-box;
  padding: 36px 48px 0 48px;
  background-image: url(${lootHeaderBg});
`;

const MarketTextAndAchieve = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MarketText = styled.div`
  max-width: 508px;

  h4 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
    margin-top: 8px;
  }
`;

const MarketAchieves = styled.div`
  display: flex;
  align-items: center;
`;
