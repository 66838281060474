import { BrowserRouter as Router, useHistory } from "react-router-dom";
import "./assets/css/index.css";
import { PageWrapper } from "./components/common/PageWrapper";
import { ProfileUserInfo } from "./utils/commonTypes";
import { socket } from "./sockets";
import React, { useCallback, useEffect, useState } from "react";
import { useGetProfile } from "./hooks/useGetProfile";
import { useLoginUser } from "./hooks/useLoginUser";
import { AlertModal, AlertModalType } from "./components/common/AlertModal";

export type GlobalContextType = {
  userInfo?: ProfileUserInfo;
  setUserInfo: (arg0: ProfileUserInfo) => void;
  loginUser: () => Promise<void>;
  setAlertModal: (data: AlertModalType) => void;
};

export const GlobalContext = React.createContext<GlobalContextType | null>(null);

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<ProfileUserInfo>();
  const { data, isLoading: loadingProfile } = useGetProfile(token);
  const { mutateAsync: login, isLoading: isLoggingIn } = useLoginUser();
  const [alertModal, setAlertModal] = useState<AlertModalType>({
    visible: false
  });

  const loginUser = useCallback(async () => {
    const result = await login();
    localStorage.setItem("authToken", result.authToken);
    setToken(result.authToken);
  }, []);

  useEffect(() => {
    if (data) setUserInfo(data.userInfo);
    socket.connect();
    socket.emit("login", { token });
  }, [data]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) setToken(token);

    (window as any).ethereum?.on("accountsChanged", function (accounts: any) {
      localStorage.removeItem("authToken");
      setUserInfo(undefined);
      socket.disconnect();
    });

    socket.on("connect", () => {
      console.log("onconnect socket");
      if (token) socket.emit("login", { token });
    });
  }, []);

  return (
    <div className="App">
      <GlobalContext.Provider value={{ userInfo, setUserInfo, loginUser, setAlertModal }}>
        <Router>
          {(isLoggingIn || loadingProfile) && (
            <div className="fixed flex justify-center items-center w-full h-full z-[1000] bg-[#000000d1] backdrop-blur-[2px] text-white text-[24px]">
              <div>Logging in...</div>
            </div>
          )}
          <PageWrapper />
          <AlertModal data={alertModal} />
        </Router>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
