import { useState, useEffect } from "react";
import { Modal } from "../Modal";
import { TedButton } from "../Button/TedButton";
import styled from "styled-components/macro";

export type AlertModalType = {
  visible: boolean;
  message?: string | null;
  type?: string | null;
  options?: any | null;
};

export function AlertModal(props: { data: any }) {
  const [alertModal, setAlertModal] = useState<AlertModalType>({
    visible: false,
    message: null,
    type: null,
    options: null
  });

  useEffect(() => {
    setAlertModal(props.data);
  }, [props]);

  return (
    <>
      {alertModal.visible && (
        <Modal
          visible={alertModal.visible}
          onClose={() =>
            setAlertModal({
              visible: false,
              message: null,
              type: null
            })
          }
        >
          <ModalContent
            style={{
              padding: "27px",
              background: "none",
              maxWidth: "min(80vw, 400px)"
            }}
          >
            {alertModal.type && (
              <h1
                style={{
                  color: alertModal.type == "success" ? "#29efa8" : "red",
                  fontSize: "25px",
                  marginBottom: "30px"
                }}
              >
                {alertModal.type == "success" ? "Success!" : "Error!"}
              </h1>
            )}
            <h1>{alertModal.message}</h1>
            {!alertModal.options?.noButtons && (
              <ModalButton>
                <TedButton
                  size="modal"
                  width="100"
                  color="green"
                  onclick={() =>
                    setAlertModal({
                      visible: false,
                      message: null,
                      type: null
                    })
                  }
                >
                  Ok
                </TedButton>
              </ModalButton>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 742px;
  padding: 30px 30px;
  max-width: 90%;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;
