import { useState } from "react";
import styled from "styled-components";
import { imageRoute } from "../../../utils/api";
import { checkImageSrc } from "../../../utils/commonFunctions";
import { PartType } from "../../../utils/commonTypes";
import { PartPickerPart } from "./PartPickerPart";
import { Filters } from "../Filters/Filters";

export const PartPicker = (props: { onPick: (part: PartType) => void }) => {
  const [parts, setParts] = useState<PartType[]>([]);
  return (
    <Filters onPartsUpdate={setParts}>
      <Parts>
        {parts?.map(part => (
          <PartPickerPart key={part.id} pick={props.onPick} part={part} imgSrc={`${imageRoute}${checkImageSrc(part.images, "Thumbnail_Layer", part.section)}`} />
        ))}
      </Parts>
    </Filters>
  );
};

const Parts = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 446px) {
    margin: 20px 15px;
  }
`;
