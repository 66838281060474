import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";

type EditBoxDataType = {
  name: string;
  price: number;
  rarity: "Gray" | "Green" | "Blue" | "Purple" | "Red" | "Yellow";
  onSale: boolean;
  canBeOpened: boolean;
};

function useAdminPostEditLootBox(id: number) {
  const context = useContext(Context);

  const { mutate, isLoading, data } = useMutation(
    (data: EditBoxDataType) =>
      axios.put(`${apiRoute}/lootboxes/update/${id}`, data, {
        headers: authHeader()
      }),
    {
      onSuccess: () => {
        context?.setMessage("Lootbox successfully updated.");
        queryClient.invalidateQueries("getAllAdminLootBoxes");
      }
    }
  );

  return { mutate, isLoading, data };
}

export { useAdminPostEditLootBox };
