import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import authHeader from "../utils/auth-header";
import { OpenedLootbox } from "../utils/commonTypes";

function useGetOpenedLootboxes() {
  const { data, isLoading } = useQuery(["getOpenedLootboxes"], () =>
    axios
      .get<OpenedLootbox[]>(`${apiRoute}/lootboxes/opened`, {
        headers: authHeader()
      })
      .then(x => x.data)
  );

  return { data, isLoading };
}

export { useGetOpenedLootboxes };
