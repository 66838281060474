import { useState } from "react";
import styled from "styled-components";
import { useGetAllRobotsInProfile } from "../../../hooks/useGetAllRobotsInProfile";
import { RobotType } from "../../../utils/commonTypes";
import { TedButton } from "../Button/TedButton";
import { CustomLoader } from "../Loader";
import { Modal } from "../Modal";

export const ModalMIPPicker = ({
  visible,
  onClose,
  onSelect,
  processing,
  highlightedId,
  status
}: {
  visible: boolean;
  processing: boolean;
  onClose: () => void;
  onSelect: (robot: RobotType) => void;
  highlightedId?: number;
  status?: string | null;
}) => {
  const { data: robots, isLoading: isGetRobotsLoading } = useGetAllRobotsInProfile();
  const [highlightedRobotId, setHighlightedRobotId] = useState<number | undefined>(highlightedId);

  return (
    <Modal visible={visible} onClose={onClose}>
      <div
        style={{
          width: "90vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h3
          style={{
            fontSize: "25px",
            marginTop: "40px",
            marginBottom: "10px",
            color: "white"
          }}
        >
          Select MIP
        </h3>
        <RobotsWrapper>
          {robots
            ?.filter(robot => robot.mip_license === "Active" && robot.status == (status ?? robot.status))
            .map((robot, i) => (
              <RobotWrapper highlighted={robot.id === highlightedRobotId} key={robot.id} onClick={() => ["NFT", "frame"].includes(robot.status) && setHighlightedRobotId(robot.id)}>
                <ItemModal>
                  {robot.reserved_for_remint == 1 && <ReservedSign>RESERVED FOR REMINT</ReservedSign>}
                  <RobotModal src={robot.status == "frame" ? "https://api.mipapp.link/dev/images?image=background-frame.jpg" : robot.image} alt="" />
                </ItemModal>
                <TextModal>
                  <h3>
                    {robot.name}
                    {robot.status == "frame" ? " (MIP Frame)" : ""}
                  </h3>
                  <p>{robot.status == "disassembled" ? "MIP Frame" : robot.status.toUpperCase()}</p>
                </TextModal>
              </RobotWrapper>
            ))}
        </RobotsWrapper>
        <ModalButton style={{ marginBottom: "25px" }}>
          {processing ? (
            <CustomLoader margin="0" height="48px" width="100px" selectMip={true} />
          ) : (
            <TedButton
              size="modal"
              width="260"
              color="green"
              onclick={() => {
                onSelect(robots?.find(x => x.id == highlightedRobotId)!);
              }}
              disabled={!highlightedRobotId}
            >
              Confirm
            </TedButton>
          )}
        </ModalButton>
      </div>
    </Modal>
  );
};

const RobotsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 232px);
  justify-content: center;
  grid-row-gap: 5px;
  column-gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  max-height: min(90vh, 600px);
  color: white;
`;

const RobotWrapper = styled.div<{
  highlighted: boolean;
}>`
  margin: 6px;
  background: #0b1014;
  border-radius: 12px;
  width: 226px;
  height: 266px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  border: 2px solid #0b1014;
  ${props =>
    props.highlighted
      ? `background: linear-gradient(90deg, #151b24 0%, #10161c 100%);
  border: 2px solid #29efa8;`
      : ""}
  : hover {
    cursor: pointer;
  }
`;

const ItemModal = styled.div`
  width: 183px;
  height: 183px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const RobotModal = styled.img`
  z-index: 5;
  height: 183px;
  border-radius: 40px;
`;

const ReservedSign = styled.h2`
  position: absolute;
  z-index: 10;
  transform: rotate(-34deg);
  color: red;
  background: black;
  padding: 2px;
`;

const TextModal = styled.div`
  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;
