import axios from "axios";
import authHeader from "../utils/auth-header";
import { apiRoute, imageRoute, queryClient } from "../utils/api";
import styled from "styled-components/macro";
import { TedButton } from "../components/common/Button/TedButton";
import garageHeaderBg from "../assets/img/backgroundGarage.png";
import petGarage from "../assets/img/petGarage.svg";
import refresh from "../assets/img/refreshButtonGarage.svg";
import { RobotItem } from "../components/common/RobotItemGarage";
import { InventoryItem } from "../components/common/InventoryItem";
import { RobotModel } from "../components/RobotModel";
import React, { useEffect, useState } from "react";
import { useGetAllRobotsInProfile } from "../hooks/useGetAllRobotsInProfile";
import { useGetParts } from "../hooks/useGetParts";
import { ParamsForDetailsFilter, PartType, ProfileUserInfo, RobotType } from "../utils/commonTypes";
import { CustomLoader } from "../components/common/Loader";
import { Pagination } from "../components/common/Pagination";
import Select from "react-select";
import { useGetParamsForDetailsFilter } from "../hooks/useGetParamsForDetailsFilter";
import { ReactComponent as SearchIcon } from "../assets/img/search.svg";
import { checkImageSrc, checkTrait, customStylesForCountrySelect, customStylesForGarageSelect } from "../utils/commonFunctions";
import { Modal } from "../components/common/Modal";
import { useMoveRobotToGarage } from "../hooks/useMoveRobotToGarage";
import diamond from "../assets/img/smallDiamond.svg";
import { useGetSellDetails } from "../hooks/useGetSellDetails";
import useMintRobot from "../hooks/useMintRobot";
import { IFilter } from "../utils/commonInterfaces";
import ThreeDots from "../components/ThreeDots/ThreeDots";
import { isGarageOperationPaid, payGarageOperation } from "../metamask/contracts";

import Countdown from "../components/Countdown/Countdown";
import { socket } from "../sockets";
import { ModalMIPPicker } from "../components/common/Modals/ModalMIPPicker";
import { useParams } from "react-router-dom";
const countries: any = require("../utils/countries-and-cities.json");

type GaragePropsType = {
  userInfo: ProfileUserInfo;
};

export const Garage = ({ userInfo }: GaragePropsType) => {
  const params = useParams<{ garageNumber: string }>();
  const [invPageNumber, setInvPageNumber] = useState(1);

  const { data: robots, isLoading: isGetRobotsLoading } = useGetAllRobotsInProfile();

  const { data: filterParams } = useGetParamsForDetailsFilter();

  const [mintModal, setMintModal] = useState<{
    visible: boolean;
    message?: React.ReactElement;
    header?: React.ReactElement;
    confirmFunction?: Function;
    options?: any | null;
  }>({
    visible: false
  });

  useEffect(() => {
    socket.on("remint-done", msg => {
      console.log("remint-done", msg);
      queryClient.invalidateQueries("getAllRobotsInProfile");
      getRemintPermissions();
      setAlertModal({
        visible: true,
        message: "Remint finished!",
        type: "success"
      });
    });

    return () => {
      socket.off("remint-done");
    };
  }, []);

  const [userAddressModal, setUserAddressModal] = useState<{
    visible: boolean;
    country?: string | null;
    countryCode?: string | null;
    city?: string | null;
    addressConfirmed: boolean;
    errors: { country: boolean; city: boolean };
  }>({
    country: null,
    countryCode: null,
    city: null,
    visible: false,
    addressConfirmed: false,
    errors: { country: false, city: false }
  });

  const [alertModal, setAlertModal] = useState<{
    visible: boolean;
    message?: string | null;
    type?: string | null;
    options?: any | null;
  }>({
    visible: false,
    message: null,
    type: null,
    options: null
  });

  useEffect(() => {
    const userAddress: any = localStorage.getItem("userAddress") != null ? JSON.parse(localStorage.getItem("userAddress") as string) : null;
    if (userAddress)
      setUserAddressModal({
        ...userAddressModal,
        country: userAddress.country,
        city: userAddress.city,
        countryCode: userAddress.countryCode
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("userAddress", JSON.stringify(userAddressModal));
  }, [userAddressModal]);

  const [mintingCooldown, setMintingCooldown] = useState<number>(-1);
  const [remintInProgress, setRemintInProgress] = useState<boolean>(false);
  const [activeGarage, setActiveGarage] = useState<number>(+params.garageNumber);
  const [selectedFilters, setSelectedFilters] = useState<object>({});
  const [searchText, setSearchText] = useState<string>("");
  const [visibleMipPicker, setVisibleMipPicker] = useState<boolean>(false);
  const [hoveredPart, setHoveredPart] = useState<{
    section: string;
    category: string;
  }>({ category: "", section: "" });

  const { mutate: moveRobotToGarage, isLoading: moveRobotLoading } = useMoveRobotToGarage(setVisibleMipPicker);

  const sectionCategories: any = {
    MIPHead: ["Eyebrows", "Mask", "Fin", "Shell", "Jetpack", "Thrusters", "Ears", "Eyes"],
    Driver: ["Hair", "Head", "Eyes", "Body", "Hands", "Pout", "Dizzy"],
    Pet: ["Body", "Ext", "Head", "Jetpack", "Tail"]
  };

  const changeFilter = (filters: IFilter[]) => {
    const filterCopy: any = { ...selectedFilters };

    for (let filter of filters) {
      const { filterName, value } = filter;
      if (value === filterCopy[filterName]) continue;
      if (!value) delete filterCopy[filterName];
      else filterCopy[filterName] = value;

      if (filterName === "section" && !sectionCategories[value]?.includes(filterCopy["category"])) delete filterCopy["category"];
    }

    setInvPageNumber(1);
    setSelectedFilters(filterCopy);
  };

  const getFilterOptions = (filterName: string) => {
    const emptyFilter = {
      value: "",
      label: `No ${filterName} filter`
    };
    const filterParamsArr = filterParams?.data[filterName as keyof ParamsForDetailsFilter]
      .filter(item => {
        if (filterName === "category") {
          const selectedSection = selectedFilters["section" as keyof object];
          return !selectedSection || sectionCategories[selectedSection].includes(item);
        }
        return true;
      })
      .map(item => {
        return {
          value: item,
          label: item
        };
      });
    return filterParamsArr ? [emptyFilter, ...filterParamsArr] : [emptyFilter];
  };

  const { data: parts, isLoading: isPartsLoading } = useGetParts({
    current_user: true,
    page: invPageNumber,
    filters: selectedFilters
  });

  const [activeRobot, setActiveRobot] = useState<RobotType | null>(null);
  const [modifiedRobot, setModifiedRobot] = useState<RobotType | null>(null);
  const [highlightedRobotId, setHighlightedRobotId] = useState<number | null>(null);

  const getRemintPermissions = async () => {
    const data = (
      await axios.get<{
        success: boolean;
        cooldown_seconds: number;
        remint_in_progress: boolean;
        modifiedRobot?: any | null;
      }>(`${apiRoute}/remint/remint-permission`, {
        headers: authHeader()
      })
    ).data;
    setMintingCooldown(data.cooldown_seconds);
    // setMintingCooldown(0);
    setRemintInProgress(data.remint_in_progress);
    if (data.remint_in_progress && data.modifiedRobot) setModifiedRobot(data.modifiedRobot);

    return data.remint_in_progress;
  };

  useEffect(() => console.log({ modifiedRobot }), [modifiedRobot]);

  useEffect(() => {
    async function robotsFunc() {
      robots?.forEach(robot =>
        robot.parts.forEach(part => {
          part.robotId = robot.id;
          part.robotName = robot.name;
        })
      );
      const remint_in_progress = await getRemintPermissions();
      if (!remint_in_progress && robots?.length) {
        const currentRobot = robots.find(robot => robot.garage?.number === activeGarage);

        if (currentRobot) {
          setActiveRobot(currentRobot);
          setModifiedRobot(currentRobot);
          if (!highlightedRobotId) setHighlightedRobotId(currentRobot.id);
        } else {
          setActiveRobot(null);
          setModifiedRobot(null);
        }
      }
    }
    if (robots) robotsFunc();
  }, [robots, activeGarage]);

  const resetMIP = () => setModifiedRobot({ ...activeRobot } as RobotType | null);

  const handleManipulate = (part: PartType, type: "add" | "remove") => {
    if (type === "add") {
      const newModifiedRobot = { ...modifiedRobot } as RobotType;
      newModifiedRobot.parts = newModifiedRobot.parts.filter(x => x.section !== part.section || x.category !== part.category);
      newModifiedRobot.parts.push(part);
      setModifiedRobot(newModifiedRobot);
    } else if (type === "remove") {
      setHoveredPart({ section: "", category: "" });
      setModifiedRobot({
        ...modifiedRobot,
        parts: modifiedRobot?.parts?.filter(x => x.id !== part.id)
      } as RobotType);
    }
  };

  const sectionParts = {
    MIPHead: {
      required: ["Eyebrows", "Mask", "Fin", "Shell", "Jetpack", "Thrusters", "Ears", "Eyes"]
    },
    Driver: {
      required: ["Hair", "Head", "Eyes", "Body", "Hands"],
      optional: ["Accessory 1", "Accessory 2"]
    }
  };

  const confirmAddress = () => {
    if (!userAddressModal.country || !userAddressModal.city) {
      setUserAddressModal({
        ...userAddressModal,
        errors: {
          country: !!userAddressModal.country,
          city: !!userAddressModal.city
        }
      });
      return;
    }

    setUserAddressModal({
      ...userAddressModal,
      addressConfirmed: true,
      visible: false,
      errors: { country: false, city: false }
    });
    remintValidation({ addressConfirmed: true, disassembleConfirmed: true });
  };

  const missingRequiredParts = () => {
    const foundCategories: any = { MIPHead: [], Driver: [] };
    const missingCategories: any = { MIPHead: [], Driver: [] };

    modifiedRobot?.parts.forEach(part => foundCategories[part.section].push(part.category));

    for (let cat of sectionParts.MIPHead.required) if (!foundCategories.MIPHead.includes(cat)) missingCategories.MIPHead.push(cat);

    // if (foundCategories.Driver.length > 0)
    for (let cat of sectionParts.Driver.required) if (!foundCategories.Driver.includes(cat)) missingCategories.Driver.push(cat);

    return missingCategories;
  };

  const remintValidation = async ({ addressConfirmed, disassembleConfirmed }: any = {}) => {
    const missingParts = missingRequiredParts();
    const missingSections = Object.keys(missingParts).filter(key => missingParts[key].length);

    if (
      !modifiedRobot?.parts.filter(x => !activeRobot?.parts.find(y => y.id == x.id)).length &&
      !activeRobot?.parts.filter(x => !modifiedRobot?.parts.find(y => y.id == x.id)).length
    ) {
      setAlertModal({
        visible: true,
        message: "You have to change at least one part.",
        type: "error"
      });
      return;
    }

    if (missingSections.length) {
      setMintModal({
        visible: true,
        options: { hideCancelButton: true },
        header: <h3>You haven't selected all the required parts.</h3>,
        message: (
          <span>
            Please select the missing required parts for: <br />
            <br />
            <span style={{ fontWeight: "bold" }}>{missingSections.join(", ")}</span>
          </span>
        )
      });

      return;
    }

    if (!disassembleConfirmed) {
      const robotsToDisassemble = new Set(modifiedRobot?.parts.filter(x => x.robotId && x.robotId != modifiedRobot.id).map(x => x.robotName));

      if (robotsToDisassemble.size) {
        setMintModal({
          visible: true,
          header: (
            <h3 style={{ fontSize: "20px", maxWidth: "min(600px, 90vw)" }}>
              Some parts are currently assigned to your other MIP NFTs.
              <br />
              <br />
              If you use them in the remint the origin MIPs will be disassembled and will take the form of a MIP Frame.
            </h3>
          ),
          message: (
            <h3 style={{ fontSize: "20px" }}>
              Following MIP NFTs will be disassembled:
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bold",
                  color: "#d30707",
                  fontSize: "25px",
                  marginTop: "10px"
                }}
              >
                {Array.from(robotsToDisassemble).join(", ")}
              </span>
            </h3>
          ),
          confirmFunction: () => {
            remintValidation({ addressConfirmed, disassembleConfirmed: true });
          }
        });

        return;
      }
    }

    if (!userAddressModal.city || !userAddressModal.country || !addressConfirmed) {
      setUserAddressModal({ ...userAddressModal, visible: true });
      return;
    }

    const account = (
      await (window as any).ethereum.request({
        method: "eth_requestAccounts"
      })
    )[0];

    const garagePaid = await isGarageOperationPaid(account);

    if (!garagePaid) {
      setAlertModal({
        visible: true,
        message: "Garage operation payment in progress...",
        options: { noButtons: true }
      });

      const result = await payGarageOperation(account);
      if (!result.success) {
        setAlertModal({
          visible: true,
          message: result.message ?? "An error occurred!",
          type: "error"
        });
        return;
      }

      setAlertModal({ visible: false });
    }

    mintNow({
      modifiedRobot: modifiedRobot as RobotType,
      country: userAddressModal.country as string,
      city: userAddressModal.city as string
    });
  };

  const mintNow = async (data: any) => {
    try {
      setAlertModal({
        visible: true,
        message: "Starting remint...",
        options: { noButtons: true },
        type: "success"
      });
      const result = await mutateRobot.mutateAsync(data);
      setAlertModal({
        visible: true,
        message: "Remint in progress...",
        type: "success"
      });
      setRemintInProgress(true);
      getRemintPermissions();
    } catch (error: any) {
      const { response } = error;
      setAlertModal({
        visible: true,
        message: response.status == 400 ? response.data.error : "Error trying to mint",
        type: "error"
      });
    }
  };

  const isPartEquipped = (part: PartType) => (modifiedRobot ? modifiedRobot.parts.findIndex(x => x.id === part.id && x.robotId === part.robotId) !== -1 : false);

  const driverParts: any = {};
  for (let part of modifiedRobot?.parts || []) if (part.section === "Driver") driverParts[part.category] = part;

  const robotParts: any = {};
  for (let part of modifiedRobot?.parts || []) if (part.section === "MIPHead") robotParts[part.category] = part;

  const petParts: any = {};
  for (let part of modifiedRobot?.parts || []) if (part.section === "Pet") petParts[part.category] = part;

  const confirmRobot = (new_robot: RobotType) => {
    if (new_robot.id !== activeRobot?.id) {
      if (activeGarage != -1)
        moveRobotToGarage({
          robotId: new_robot.id,
          garage_number: activeGarage
        });
      else setVisibleMipPicker(false);

      setActiveRobot(new_robot);
      setModifiedRobot(new_robot);
    } else setVisibleMipPicker(false);
  };

  const { mutate: dataSellDetail } = useGetSellDetails();

  const [visAcceptSellDetail, setVisAcceptSellDetail] = useState<{
    name: string;
    price: number;
    id: number;
  } | null>(null);

  const sellDetail = (id: number) => {
    dataSellDetail({ id });
    setVisAcceptSellDetail(null);
  };

  const mutateRobot = useMintRobot();

  return (
    <GarageContainer>
      <MenuGarage>
        {userInfo.garages.map(garage => (
          <GarageTab locked={garage.locked} key={garage.number} active={activeGarage === garage.number} onClick={() => !garage.locked && setActiveGarage(garage.number)}>
            Garage {garage.number}
          </GarageTab>
        ))}
        <GarageTab active={activeGarage === -1} onClick={() => setActiveGarage(-1)}>
          Build new MIP
        </GarageTab>
      </MenuGarage>
      {isGetRobotsLoading ? (
        <CustomLoader margin="100px 0" />
      ) : (
        <>
          <GarageInterfaceRobot>
            <LeftRobotsGarage>
              <h1>Robot</h1>
              <GarageHeaderRobot>
                {Object.values(sectionParts["MIPHead"])
                  .reduce((acc, curr) => [...acc, ...curr], [])
                  .map((category, i) => {
                    const part = robotParts[category];
                    return (
                      <RobotItem
                        key={category}
                        part={part}
                        enabled={activeRobot != null}
                        sell={part?.price || 0}
                        manipulate={handleManipulate}
                        changeFilter={changeFilter}
                        id={part?.id}
                        descr={part?.type}
                        nameItems={part?.name}
                        imgSrc={part?.images ? checkImageSrc(part.images, "Thumbnail_Layer", part.section) : ""}
                        name={part?.name}
                        section={"MIPHead"}
                        category={category}
                        idRob={modifiedRobot?.id || 0}
                        setHoveredPart={setHoveredPart}
                      />
                    );
                  })}
              </GarageHeaderRobot>

              <h2>Driver</h2>
              <GarageHeaderRobot>
                {Object.values(sectionParts["Driver"])
                  .reduce((acc, curr) => [...acc, ...curr], [])
                  .map((category, i) => {
                    const part = driverParts[category];
                    return (
                      <RobotItem
                        key={category}
                        part={part}
                        enabled={activeRobot != null}
                        sell={part?.price || 0}
                        manipulate={handleManipulate}
                        changeFilter={changeFilter}
                        id={part?.id}
                        descr={part?.type}
                        nameItems={part?.name}
                        imgSrc={part?.images ? checkImageSrc(part.images, "Thumbnail_Layer", part.section) : ""}
                        name={part?.name}
                        section={"Driver"}
                        category={category}
                        idRob={modifiedRobot?.id || 0}
                        setHoveredPart={setHoveredPart}
                      />
                    );
                  })}
              </GarageHeaderRobot>
            </LeftRobotsGarage>

            <MainRobotGarage>
              <h1
                style={{
                  fontSize: "22px",
                  margin: "35px 0px",
                  textAlign: "center"
                }}
              >
                {modifiedRobot ? `${modifiedRobot.name}${modifiedRobot.status == "frame" ? " (MIP Frame) " : ""}` : "No MIP selected"}
              </h1>
              {remintInProgress ? (
                <h1
                  style={{
                    fontSize: "22px",
                    color: "#29efa8",
                    textAlign: "center",
                    marginBottom: "30px"
                  }}
                >
                  Remint in progress
                  <ThreeDots />
                </h1>
              ) : (
                mintingCooldown > 0 && (
                  <h1
                    style={{
                      fontSize: "22px",
                      color: "#29efa8",
                      textAlign: "center",
                      marginBottom: "30px"
                    }}
                  >
                    Next remint available in <Countdown seconds={mintingCooldown} updateSeconds={setMintingCooldown} />
                  </h1>
                )
              )}
              {modifiedRobot && (
                <div style={{ flexGrow: 1, position: "relative" }}>
                  <RobotModel robotParts={modifiedRobot.parts} hoveredPart={hoveredPart} />
                </div>
              )}
            </MainRobotGarage>

            <RightPetsGarage>
              <GaragePetRobot>
                <PetRobot src={petGarage} />
              </GaragePetRobot>
              <h1>Pet</h1>
              <GarageHeaderPets>
                {/* {petParts?.map(
                  detail =>
                    detail?.images.length && (
                      <RobotItem
                        sell={detail.price || 0}
                        manipulate={handleManipulate}
                        id={detail.id}
                        descr={detail.type}
                        category={detail.category}
                        nameItems={detail.name}
                        imgSrc={checkImageSrc(
                          detail?.images,
                          "Thumbnail_Layer",
                          detail?.section
                        )}
                        key={detail?.id}
                        name={checkTrait(detail.partparams)}
                        idRob={activeRobot?.id || 0}
                      />
                    ),
                )} */}
              </GarageHeaderPets>
              {!petParts?.length && <p>You have no apply pet details yet</p>}
            </RightPetsGarage>
          </GarageInterfaceRobot>

          <ButWrapper>
            {/* <Cheker>
          <p>Preview mode</p>
        </Cheker> */}
            <SelectButtonWrapper>
              <TedButton
                disabled={remintInProgress}
                color="green"
                width="100%"
                size="big"
                onclick={() => {
                  setVisibleMipPicker(true);
                }}
                // loading={mintingCooldown != 0}
              >
                SELECT MIP
              </TedButton>
            </SelectButtonWrapper>

            <ReturnButtonWrapper>
              <TedButton
                disabled={mintingCooldown != 0 || remintInProgress}
                color="blue"
                width="100%"
                size="big"
                refresh={true}
                // onclick={() => refreshNFT({ wallet: props.wallet })}
                onclick={resetMIP}
              >
                <img src={refresh} alt="" />
              </TedButton>
            </ReturnButtonWrapper>

            <MintNowButtonWrapper>
              <TedButton
                disabled={!modifiedRobot || mintingCooldown != 0 || remintInProgress}
                onclick={() => {
                  setUserAddressModal({
                    ...userAddressModal,
                    addressConfirmed: false
                  });
                  remintValidation();
                }}
                color="yellow"
                width="100%"
                size="big"
                // loading={mintingCooldown != 0}
              >
                MINT NOW
              </TedButton>
            </MintNowButtonWrapper>
          </ButWrapper>
        </>
      )}
      {robots && activeRobot && (
        <InventoryWrapper>
          <InventoryTools>
            <InvFilter>
              <h3>Inventory</h3>
              <InputWrapper>
                <SearchIcon />
                <Input
                  value={searchText}
                  onKeyDown={e => {
                    if (e.key === "Enter")
                      changeFilter([
                        {
                          filterName: "searchText",
                          value: e.currentTarget.value
                        }
                      ]);
                  }}
                  onBlur={e => {
                    changeFilter([
                      {
                        filterName: "searchText",
                        value: e.currentTarget.value
                      }
                    ]);
                  }}
                  onChange={e => setSearchText(e.currentTarget.value)}
                  placeholder="Enter your search"
                />
              </InputWrapper>
            </InvFilter>
            <InvSelect>
              {Object.keys(filterParams?.data || {}).map((filterName, i) => (
                <SelectWrapper key={i}>
                  <Select
                    value={
                      selectedFilters[filterName as keyof object]
                        ? {
                            value: selectedFilters[filterName as keyof object],
                            label: selectedFilters[filterName as keyof object]
                          }
                        : null
                    }
                    onChange={(e: any) => changeFilter([{ filterName, value: e?.value || null }])}
                    options={getFilterOptions(filterName)}
                    styles={customStylesForGarageSelect}
                    placeholder={`${filterName.toUpperCase()}`}
                  />
                </SelectWrapper>
              ))}
            </InvSelect>
          </InventoryTools>

          <InventoryItems id="inventoryItems">
            {isPartsLoading ? (
              <CustomLoader margin="20px 0 0 30px;" />
            ) : !parts?.data.result.length ? (
              <NoItemTitle>You have no items in inventory now!</NoItemTitle>
            ) : (
              parts?.data.result.map((part, i) => {
                let partsToShow: any[] = [];

                if (part.added_to_robots?.length)
                  partsToShow = partsToShow.concat(
                    part.added_to_robots.map(x => {
                      return {
                        ...part,
                        robotId: x.robotId,
                        robotName: x.robotName
                      };
                    })
                  );

                if ((part.count ?? 0) > (part.added_to_robots?.length || 0)) partsToShow.push(part);

                return partsToShow.map(part => (
                  <InventoryItem
                    key={`${part.id}${part.robotId || 0}`}
                    part={part}
                    openSellingModal={setVisAcceptSellDetail}
                    imgSrc={`${imageRoute}${checkImageSrc(part.images, "Thumbnail_Layer", part.section)}`}
                    isEquipped={isPartEquipped(part)}
                    manipulate={handleManipulate}
                    count={part.robotId ? 1 : (part.count ?? 0) - (part.added_to_robots?.length || 0)}
                  />
                ));
              })
            )}
          </InventoryItems>
          {(parts?.data.count || 0) > 16 ? (
            <PaginationWrapper>
              <Pagination setPageNumber={setInvPageNumber} pageNumber={invPageNumber} itemsPerPage={16} itemsCount={parts?.data.count || 0} />
            </PaginationWrapper>
          ) : (
            ""
          )}
        </InventoryWrapper>
      )}
      <ModalMIPPicker
        visible={visibleMipPicker}
        onClose={() => setVisibleMipPicker(false)}
        onSelect={confirmRobot}
        processing={moveRobotLoading}
        status={activeGarage != -1 ? "NFT" : null}
      />
      {visAcceptSellDetail && (
        <Modal visible={!!visAcceptSellDetail} onClose={() => setVisAcceptSellDetail(null)}>
          <SellModalContent>
            <h3>Confirm your actions</h3>
            <SellRobotsWrapper>
              <div>
                Are you sure you want to sell:
                <br />
                <span style={{ fontWeight: "bold" }}>{visAcceptSellDetail.name}</span>?
                <Diamonds>
                  <span>for </span> <p>{visAcceptSellDetail.price}</p> <img src={diamond} alt="" />
                </Diamonds>
              </div>
            </SellRobotsWrapper>
            <SellModalButton>
              <AcceptSell onClick={() => sellDetail(visAcceptSellDetail.id)}>Yes</AcceptSell>
              <NotSell onClick={() => setVisAcceptSellDetail(null)}>No</NotSell>
            </SellModalButton>
          </SellModalContent>
        </Modal>
      )}
      {mintModal.visible && (
        <Modal
          visible={mintModal.visible}
          onClose={() => {
            setMintModal({ visible: false });
          }}
        >
          <ModalContent>
            {mintModal.header}
            <br />
            <div style={{ marginTop: "15px" }}>{mintModal.message}</div>
            <div>
              <ModalButton style={{ marginRight: "10px", display: "inline-flex" }}>
                <TedButton
                  size="modal"
                  width="100"
                  color="green"
                  onclick={() => {
                    setMintModal({ visible: false });
                    if (mintModal.confirmFunction) mintModal.confirmFunction();
                  }}
                >
                  Ok
                </TedButton>
              </ModalButton>
              {!mintModal.options?.hideCancelButton && (
                <ModalButton style={{ marginLeft: "10px", display: "inline-flex" }}>
                  <TedButton
                    size="modal"
                    width="100"
                    color="yellow"
                    onclick={() => {
                      setMintModal({ visible: false });
                    }}
                  >
                    Cancel
                  </TedButton>
                </ModalButton>
              )}
            </div>
          </ModalContent>
        </Modal>
      )}
      {userAddressModal.visible && (
        <Modal visible={userAddressModal.visible}>
          <ModalContent>
            <h3>Please enter you location: </h3>
            <br />
            <SelectWrapper style={{ width: "361px", margin: "15px 0 0 0" }}>
              <Select
                value={
                  userAddressModal.country
                    ? {
                        countryCode: userAddressModal.countryCode,
                        value: userAddressModal.country,
                        label: userAddressModal.country
                      }
                    : null
                }
                onChange={(e: any) =>
                  setUserAddressModal({
                    ...userAddressModal,
                    country: e?.value || null,
                    countryCode: e?.countryCode || null,
                    city: null
                  })
                }
                options={Object.keys(countries).map(key => ({
                  value: countries[key].name,
                  label: countries[key].name,
                  countryCode: key
                }))}
                styles={customStylesForCountrySelect}
                placeholder="Select country..."
              />
            </SelectWrapper>
            <SelectWrapper style={{ width: "361px", margin: "15px 0 0 0" }}>
              <Select
                value={
                  userAddressModal.city
                    ? {
                        value: userAddressModal.city,
                        label: userAddressModal.city
                      }
                    : null
                }
                onChange={(e: any) =>
                  setUserAddressModal({
                    ...userAddressModal,
                    city: e?.value || null
                  })
                }
                options={(userAddressModal.countryCode ? (countries[userAddressModal.countryCode].cities as [string]) : []).map(city => ({
                  value: city,
                  label: city
                }))}
                styles={customStylesForCountrySelect}
                placeholder="Select city..."
              />
            </SelectWrapper>
            <div>
              <ModalButton style={{ marginRight: "10px", display: "inline-flex" }}>
                <TedButton size="modal" width="100" color="green" onclick={() => confirmAddress()}>
                  Ok
                </TedButton>
              </ModalButton>
              <ModalButton style={{ marginLeft: "10px", display: "inline-flex" }}>
                <TedButton
                  size="modal"
                  width="100"
                  color="yellow"
                  onclick={() => {
                    setUserAddressModal({
                      ...userAddressModal,
                      visible: false,
                      addressConfirmed: false
                    });
                  }}
                >
                  Cancel
                </TedButton>
              </ModalButton>
            </div>
          </ModalContent>
        </Modal>
      )}
      {alertModal.visible && (
        <Modal
          visible={alertModal.visible}
          onClose={() =>
            setAlertModal({
              visible: false,
              message: null,
              type: null
            })
          }
        >
          <ModalContent
            style={{
              padding: "27px",
              background: "none",
              maxWidth: "min(80vw, 400px)"
            }}
          >
            {alertModal.type && (
              <h1
                style={{
                  color: alertModal.type == "success" ? "#29efa8" : "red",
                  fontSize: "25px",
                  marginBottom: "30px"
                }}
              >
                {alertModal.type == "success" ? "Success!" : "Error!"}
              </h1>
            )}
            <h1>{alertModal.message}</h1>
            {!alertModal.options?.noButtons && (
              <ModalButton>
                <TedButton
                  size="modal"
                  width="100"
                  color="green"
                  onclick={() =>
                    setAlertModal({
                      visible: false,
                      message: null,
                      type: null
                    })
                  }
                >
                  Ok
                </TedButton>
              </ModalButton>
            )}
          </ModalContent>
        </Modal>
      )}
    </GarageContainer>
  );
};

const NoItemTitle = styled.div``;

const InputWrapper = styled.div`
  width: 361px;
  height: 46px;
  background: #161d24;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 12px;

  @media (max-width: 1150px) {
    width: auto;
  }

  @media (max-width: 980px) {
    margin-left: 0;
    margin-top: 15px;
    width: calc(100% - 15px);
  }
`;

const Input = styled.input`
  margin-left: 12px;
  background: none;
  outline: none;
  border: none;
  color: #fff;
  width: 100%;

  ::placeholder {
    color: #616d7a;
  }
`;

const InventoryWrapper = styled.div`
  margin: 27px 0 32px 0;
  width: 100%;
`;

const InventoryTools = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 38px;
  }
`;

const InventoryItems = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 446px) {
    margin: 20px 15px;
  }
`;

const InvFilter = styled.div`
  display: flex;
  margin-left: 48px;

  > h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const InvSelect = styled.div`
  margin-right: 48px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 980px) {
    width: calc(100% - 15px);
    justify-content: space-between;
    margin-right: 0;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: normal;
  }
`;

const ButWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 680px;
  margin-bottom: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    width: auto;
  }
`;

const RightPetsGarage = styled.div`
  width: 370px;
  height: 529px;
  margin-left: 60px;

  > h1 {
    margin: 20px 0 32px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
  }

  @media (max-width: 1350px) {
    height: auto;
    order: 3;
    width: auto;
    margin: 0 88px 40px 88px;
    align-self: flex-start;
  }

  @media (max-width: 480px) {
    margin: 0 5% 30px 5%;
  }
`;

const GaragePetRobot = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 23px;
`;

const PetRobot = styled.img`
  height: 178px;
`;

const LeftRobotsGarage = styled.div`
  margin: 60px 200px 32px 88px;
  width: 550px;

  > h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
  }

  > h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-top: 40px;
  }

  @media (max-width: 1366px) {
    width: 530px;
    height: auto;
  }

  @media (max-width: 1350px) {
    order: 2;
    width: auto;
    margin: 0 88px;
    align-self: flex-start;
  }

  @media (max-width: 480px) {
    margin: 0 5%;
  }
`;

const MainRobotGarage = styled.div`
  width: 552px;
  margin-left: -205px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
  }

  @media (max-width: 1350px) {
    order: 1;
    height: 552px;
    margin-left: 0;
  }

  @media (max-width: 980px) {
    width: 450px;
    height: 450px;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: 400px;
  }
`;

const GarageHeaderRobot = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 406px) {
    justify-content: center;
  }

  > div {
    margin: 18px 55px 0 0;

    > .noSelection {
      z-index: 20;
      position: absolute;
      top: 6%;
      max-width: 69px;
    }
  }

  @media (max-width: 1050px) {
    > div {
      width: 116px;
      margin: 18px 5px 0 0;

      > .noSelection {
        top: -7%;
      }
    }
  }
`;

const GarageHeaderPets = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 25px 20px 0;
  }
`;

const GarageInterfaceRobot = styled.div`
  background-image: url(${garageHeaderBg});
  width: 100%;
  display: flex;
  box-sizing: border-box;
  background-size: cover;
  z-index: 0;

  @media (max-width: 1350px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
`;

const GarageTab = styled.div<{ active: boolean; locked?: boolean }>`
  background: ${props => (props.active ? "#0a0b0d" : "none")};
  border-radius: 12px 12px 0px 0px;
  width: 200px;
  height: 50px;
  align-items: end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  ${props =>
    props.locked
      ? `
    color: #666666b0;
    cursor: not-allowed;
  `
      : null}
`;

const MenuGarage = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(12, 14, 19, 0) 0%, #131a22 100%);
  width: 100%;
  height: 77px;
`;

const GarageContainer = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectWrapper = styled.div`
  width: 192px;
  height: 46px;
  margin-left: 12px;
  margin-bottom: 12px;

  @media (max-width: 980px) {
    margin: 0;
    width: 35%;
  }

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
    width: 100%;
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 742px;
  padding: 30px 30px;
  max-width: 90%;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;

const Diamonds = styled.div`
  place-content: center;
  margin-top: 20px;
  margin-right: 8px;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    color: #ffffff;
    margin-left: 6px;
  }

  > p {
    font-size: 16px;
    color: #ffffff;
    margin-left: 6px;
    color: #ffd63d;
  }

  > img {
    margin-left: 5px;
  }
`;

const SellModalButton = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  > div {
    padding: 8px 18px;
    border-radius: 8px;
    margin: 0 25px;
    :hover {
      cursor: pointer;
    }
  }
`;

const SellRobotsWrapper = styled.div`
  line-height: 24px;
  display: flex;
  height: 160px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const SellModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 442px;
  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }
  > img {
    margin-top: 12px;
  }
`;

const AcceptSell = styled.div`
  background: green;
  color: #fff;
`;
const NotSell = styled.div`
  background: red;
  color: #fff;
`;

const ReturnButtonWrapper = styled.div`
  width: 78px;

  @media (max-width: 800px) {
    order: 1;
  }
`;

const SelectButtonWrapper = styled.div`
  width: 210px;

  @media (max-width: 800px) {
    order: 2;
    margin: 15px 0;
  }
`;

const MintNowButtonWrapper = styled.div`
  width: 342px;

  @media (max-width: 800px) {
    order: 3;
  }
`;
