import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";
import { LootboxItem } from "../utils/commonTypes";

function useAdminUpsertLootboxItems() {
  const context = useContext(Context);

  const { mutate, isLoading, data } = useMutation(
    (data: { lootboxId: number; items: LootboxItem[] }) =>
      axios.post(`${apiRoute}/lootboxes/items/upsert/${data.lootboxId}`, data.items, {
        headers: authHeader()
      }),
    {
      onSuccess: () => {
        context?.setMessage("Lootbox successfully saved.");
        queryClient.invalidateQueries("getAdminLootBox");
      }
    }
  );

  return { mutate, isLoading, data };
}

export { useAdminUpsertLootboxItems };
