import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import authHeader from "../utils/auth-header";
import { PartsResponse } from "../utils/commonTypes";

type GetPartsParamsType = {
  current_user?: boolean;
  page: number;
  order?: string[];
  filters: object;
};

function useGetParts(props: GetPartsParamsType) {
  const { data, isLoading } = useQuery(
    ["getParts", props],
    () =>
      axios.post<PartsResponse>(
        `${apiRoute}/parts/getall`,
        {
          current_user: props.current_user || false,
          pagination: {
            page: props.page || 1,
            limit: 21,
          },
          order: props.order || [],
          filters: props.filters,
        },
        {
          headers: authHeader(),
        },
      ),
    {
      enabled: !!props.page,
    },
  );

  return { data, isLoading };
}

export { useGetParts };
