import axios from "axios";
import { Dispatch, SetStateAction, useContext } from "react";
import { useMutation } from "react-query";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";

function useRemoveFromGarage() {
  const context = useContext(Context);

  const { mutate, mutateAsync, isLoading, data } = useMutation(
    (garage_number: number) =>
      axios.post(
        `${apiRoute}/robots/remove-from-garage`,
        { garage_number },
        {
          headers: authHeader()
        }
      ),
    {
      onSuccess: () => {
        context?.setMessage("Removed robot from garage");
        queryClient.invalidateQueries("getProfile");
        queryClient.invalidateQueries("getAllRobotsInProfile");
        queryClient.invalidateQueries("getParts");
      }
    }
  );

  return { mutate, mutateAsync, isLoading, data };
}

export { useRemoveFromGarage };
