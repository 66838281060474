import { useState } from "react";
import styled from "styled-components";
import { imageRoute } from "../../utils/api";
import { Modal } from "../common/Modal";
import { checkImageSrc } from "../../utils/commonFunctions";
import { ModalAdminEditDetailMarket } from "../common/Modals/ModalAdminEditDetailMarket";
import { PartType } from "../../utils/commonTypes";
import { Filters } from "../common/Filters/Filters";
import { AdminMarketItem } from "./AdminMarketItem";

export const AdminMarket = () => {
  const [activeId, setActiveId] = useState<number>(0);
  const [parts, setParts] = useState<PartType[]>([]);

  const openEditModal = (id: number) => {
    setActiveId(id);
  };

  const closeEditModal = () => {
    setActiveId(0);
  };

  return (
    <AdminContentWrapper>
      <h1 style={{ fontWeight: 600, fontSize: "45px", lineHeight: "48px" }}>Market</h1>
      <Filters onPartsUpdate={setParts}>
        {parts?.map(part => (
          <AdminMarketItem key={part.id} part={part} edit={openEditModal} imgSrc={`${imageRoute}${checkImageSrc(part.images, "Thumbnail_Layer", part.section)}`} />
        ))}
      </Filters>

      {activeId != 0 && (
        <Modal visible={!!activeId} onClose={() => closeEditModal()}>
          <ModalAdminEditDetailMarket idDetail={activeId} onClose={() => closeEditModal()} />
        </Modal>
      )}
    </AdminContentWrapper>
  );
};

const AdminContentWrapper = styled.div`
  color: #ffffff;
  margin: -50px 0 -50px 0;
  min-height: calc(100vh - 90px);
  background: #050b1c;
  padding-top: 130px;
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 100%;
`;
