import React from "react";
import styled from "styled-components";
import { imageRoute } from "../../utils/api";
import { ImageTypeInRobotPart, PartType } from "../../utils/commonTypes";
import "./RobotModel.css";

type RobotModelPropsType = {
  robotParts: PartType[];
  hoveredPart: { section: string; category: string };
};

export const RobotModel = (props: RobotModelPropsType) => {
  const hoverStyle: any = {
    MIPHead: {
      Fin: {
        transform: "translate(0px, -35px)",
      },
      Mask: {
        transform: "translate(9px, 47px)",
      },
      Eyes: {
        transform: "translate(30px, 0px)",
      },
      Eyebrows: {
        transform: "translate(15px, -15px)",
      },
      Jetpack: {
        transform: "translate(-30px, 30px)",
      },
      Shell: {
        Left: {
          transform: "translate(60px, 15px)",
        },
        Right: {
          transform: "translate(60px, 15px)",
        },
      },
      Ears: {
        Left: {
          transform: "translate(-20px, -50px)",
        },
        Right: {
          transform: "translate(30px, -50px)",
        },
      },
      Thrusters: {
        transform: "translate(15px, 55px)",
      },
    },
    Driver: {
      Hair: {
        transform: "translate(15px, -20px)",
      },
      Head: {
        transform: "translate(15px, -20px)",
      },
      Eyes: {
        transform: "translate(15px, -15px)",
      },
      Body: {
        transform: "translate(15px, -20px)",
      },
      Hands: {
        transform: "translate(15px, -20px)",
      },
    },
  };

  return (
    <>
      {props.robotParts.map((part, i) => (
        <React.Fragment key={(part.id || 0) + (part.robotId || 0) + i}>
          {part.images
            .filter(x => x.type === "Layer")
            .map((x, i) => {
              let partStyle = {};
              if (props.hoveredPart.section)
                if (
                  part.section === props.hoveredPart.section &&
                  part.category === props.hoveredPart.category
                )
                  partStyle = {
                    filter:
                      "drop-shadow(1px 1px 0 #29efa8) drop-shadow(-1px 1px 0 #29efa8) drop-shadow(1px -1px 0 #29efa8) drop-shadow(-1px -1px 0 #29efa8)",
                    transform: ["Ears", "Shell"].includes(part.category)
                      ? hoverStyle[part.section][part.category][x.sub_position]
                          .transform
                      : hoverStyle[part.section][part.category].transform,
                    zIndex: 100 + x.z_index,
                  };
                else
                  partStyle = {
                    opacity: 0.2,
                    // filter:
                    //   "drop-shadow(1px 1px 0 #29efa8) drop-shadow(-1px 1px 0 #29efa8) drop-shadow(1px -1px 0 #29efa8) drop-shadow(-1px -1px 0 #29efa8)",
                  };

              return (
                <RobotModelImage
                  className="robotModelImage"
                  key={(x.id || 0) + i}
                  index={x.z_index}
                  src={`${imageRoute}${x.key}`}
                  style={{
                    transition: "0.3s all",
                    transitionProperty: "transform, opacity",
                    ...partStyle,
                  }}
                />
              );
            })}
        </React.Fragment>
      ))}
    </>
  );
};

const RobotModelImage = styled.img<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  z-index: ${props => props.index};
`;
