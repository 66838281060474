import styled from "styled-components";
import { factionColor } from "../../utils/commonMaps";
import { LootboxItem } from "../../utils/commonTypes";
import borderMarket from "../../assets/img/borderMarketItem.svg";
import { ReactComponent as ItemBackground } from "../../assets/img/modalBGItemSpin.svg";
import StarBackground from "../../assets/img/starBackground.png";
import { imageRoute } from "../../utils/api";
import { checkImageSrc } from "../../utils/commonFunctions";
import { Tier } from "./Tier";
import smallDiamond from "../../assets/img/smallDiamond.svg";
import mipCoin from "../../assets/img/coin200x200.png";

type Settings = {
  height?: string;
  hideBottomBorder?: boolean;
  hideBackground?: boolean;
  hideCategory?: boolean;
  hidePrice?: boolean;
  tier?: { hideLeft?: boolean; hideRight?: boolean };
};

export const LootItem = (props: { item: LootboxItem; settings?: Settings }) => {
  const item = props.item;
  return (
    <LootItemWrapper settings={props.settings} borderColor={item.part ? factionColor[item.part.faction] : "#fff659"}>
      {item.type == "part" && item.part!.in_stock < 1 && (
        <Depleted>
          <OutOfStock>Out of stock</OutOfStock>
          <ReplacementAward>
            <span>Replacement award: {item.substitute_tokens}</span>{" "}
            <img src={smallDiamond} alt="" style={{ width: "12px", position: "relative", top: "-1px", marginLeft: "4px" }} />
          </ReplacementAward>
        </Depleted>
      )}
      <Tier
        tier={item.part?.subfaction ?? ""}
        settings={{ hideLeft: item.type == "tokens" || props.settings?.tier?.hideLeft, hideRight: item.type == "tokens" || props.settings?.tier?.hideRight }}
      />
      <Main>
        <img style={{ position: "absolute", height: "100%" }} src={borderMarket} alt="" />
        <ItemBackgroundStyled color={item.part ? factionColor[item.part.faction] : "#fff659"} />
        {/* <img src={StarBackground} style={{ zIndex: 1, opacity: 0.5 }} /> */}
        <ItemImg src={item.type == "part" ? `${imageRoute}${checkImageSrc(item.part!.images, "Thumbnail_Layer", item.part!.section)}` : mipCoin} alt="" />
        <Description>{item.part?.name.split(" ").slice(-2, -1).join(" ") || "Tokens"}</Description>
      </Main>
      <Name>{item.token_amount ?? item.part?.name.split(" ").slice(-2).join(" ")}</Name>
      {item.part && !props.settings?.hideCategory && <Category>{item.part.category}</Category>}
      {item.part && !props.settings?.hidePrice && (
        <Price>
          <span style={{ marginRight: "2px" }}>{item.part.price}</span>
          <img src={smallDiamond} style={{ marginLeft: "2px" }} alt="" />
        </Price>
      )}
    </LootItemWrapper>
  );
};

const Depleted = styled.div`
  position: absolute;
  width: 100%;
  top: 40%;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const OutOfStock = styled.div`
  color: red;
  background-color: #00000085;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  backdrop-filter: blur(2px);
  // border: 1px solid red;
  padding: 2px 0;
  padding-top: 4px;
  width: 90%;
  text-align: center;
`;

const ReplacementAward = styled.div`
  color: red;
  background-color: #00000085;
  color: gold;
  padding: 2px 0;
  padding-bottom: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  backdrop-filter: blur(2px);
  // border: 1px solid gold;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-basis: 100%;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  min-height: 0;
`;

const LootItemWrapper = styled.div<{ borderColor: string; settings: Settings | undefined }>`
  display: inline;
  width: 220px;
  height: ${props => props.settings?.height ?? "220px"};
  max-width: 100%;
  max-height: 100%;
  display: flex;
  ${props => (props.settings?.hideBackground ? "" : "background: #0b1014;")}
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${props => (props.settings?.hideBottomBorder ? `none` : `8px solid ${props.borderColor}`)};
  position: relative;
  flex-direction: column;
  padding: 25px 0 15px 0;
`;

const ItemBackgroundStyled = styled(ItemBackground)<{
  color: string;
}>`
  fill: ${props => props.color};

  position: absolute;
  width: 100%;
`;

const ItemImg = styled.img`
  height: 80%;
  z-index: 1;
`;

const Description = styled.div`
  position: absolute;
  color: #fff;
  border-radius: 6px;
  padding: 4px 13px;
  bottom: -5%;
  background: #000;
  z-index: 2;
  font-size: 14px;
`;

const Name = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  z-index: 1;
  margin-top: 20px;
`;

const Category = styled.div`
  margin-top: 10px;
  color: #616d7a;
  font-size: 13px;
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #ffd63d;
  font-size: 15px;
`;
