import styled from "styled-components";
import { Lootbox } from "../components/common/Lootbox";
import lootHeaderBg from "../assets/img/lootHeaderBg.png";
import { Achieve } from "../components/common/Achieve";
import { useState } from "react";
import { ReactComponent as DiamondIcon } from "../assets/img/smallDiamond.svg";
import { useAdminGetAllLootboxes } from "../hooks/useAdminGetAllLootboxes";
import { Lootbox as LootboxType, ProfileUserSpins } from "../utils/commonTypes";
import diamondImg from "../assets/img/smallDiamond.svg";
import boxImg from "../assets/img/smallBox.svg";
import starImg from "../assets/img/star.svg";

type LootPropsType = {
  balance: number;
  countCase: number;
  dailyYieldBonus: number;
};

const rarityTabs = [
  {
    text: "1-9 MIP",
    value: "1-9 MIP",
    minPrice: 1,
    maxPrice: 9,
    img: true
  },
  {
    text: "20-39 MIP",
    value: "20-39 MIP",
    minPrice: 20,
    maxPrice: 39,
    img: true
  },
  {
    text: "40-100 MIP",
    value: "40-100 MIP",
    minPrice: 40,
    maxPrice: 100,
    img: true
  },
  {
    text: "Claim your lootbox",
    value: "Claim your lootbox",
    minPrice: 0,
    maxPrice: 0,
    type: "claimable",
    img: false
  }
];

export const Loot = (props: LootPropsType) => {
  const [activeTab, setActiveTab] = useState<string>("1-9 MIP");
  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(9);
  const [type, setType] = useState<string | null>(null);

  const achievements: {
    img: string;
    imgWidth: string;
    text: string;
    description: string;
  }[] = [
    {
      img: diamondImg,
      imgWidth: "32px",
      text: props.balance.toString(),
      description: "Tokens"
    },
    {
      img: boxImg,
      imgWidth: "30px",
      text: props.countCase.toString(),
      description: "Lootboxes"
    },
    {
      img: starImg,
      imgWidth: "26px",
      text: `+${props.dailyYieldBonus.toString()}%`,
      description: "Bonus"
    }
  ];

  const handleClick = (newTab: string, min?: number, max?: number, type?: string) => {
    setActiveTab(newTab);
    if (type) {
      setType(type);
    } else {
      setType(null);
      min ? setMin(min) : setMin(0);
      max ? setMax(max) : setMax(0);
    }
  };

  const { data: lootBoxesData, isLoading: loadingLootboxes } = useAdminGetAllLootboxes({
    limit: 10,
    maxPrice: max,
    minPrice: min,
    page: 1,
    type
  });

  const allLootboxes = lootBoxesData?.data.rows || [];

  return (
    <LootContainer>
      <LootHeader>
        <LootTextAndAchieve>
          <LootText>
            <h4>Loot section</h4>
            <p>But because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful</p>
          </LootText>
          <LootAchieves>
            {achievements.map((ach, i) => (
              <Achieve key={i} img={ach.img} text={ach.text} descr={ach.description} imgWidth={ach.imgWidth} />
            ))}
          </LootAchieves>
        </LootTextAndAchieve>
        <LootTabsAndSort>
          <LootTabs>
            {rarityTabs.map(tab => (
              <LootTab key={tab.value} onClick={() => handleClick(tab.value, tab.minPrice, tab.maxPrice, tab.type)} active={activeTab === tab.value}>
                <span>{tab.text}</span>
                {tab.img && <DiamondIcon />}
              </LootTab>
            ))}
          </LootTabs>
        </LootTabsAndSort>
      </LootHeader>
      <LootItemsContainer>
        {allLootboxes.map(lootbox => (
          <Lootbox
            key={lootbox.id}
            id={lootbox.id}
            name={lootbox.name}
            price={lootbox.price}
            rarity={lootbox.rarity}
            src={lootbox.image ?? ""}
            claimable={lootbox.claimable_by_ticket_id != null}
            disabled={false}
            canBeOpened={lootbox.canBeOpened}
            onSale={lootbox.onSale}
          />
        ))}

        {loadingLootboxes && <div>Loading...</div>}
        {!loadingLootboxes && !allLootboxes.length && <MessageNoItem>No lootboxes found</MessageNoItem>}
      </LootItemsContainer>
    </LootContainer>
  );
};

const MessageNoItem = styled.div`
  width: 500px;
`;

const LootContainer = styled.div`
  color: #fff;
`;

const LootHeader = styled.div`
  height: 251px;
  box-sizing: border-box;
  padding: 36px 48px 0 48px;
  background-image: url(${lootHeaderBg});
  background-color: rgba(19, 26, 34, 0.5);
`;

const LootTabsAndSort = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;

const LootTabs = styled.div`
  display: flex;
`;

const LootTab = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 0;
  width: 200px;
  opacity: ${props => (props.active ? 1 : 0.5)};

  ${props =>
    props.active &&
    `background: #0A0B0D;
  border-radius: 12px 12px 0px 0px;`}

  :hover {
    cursor: pointer;
  }
  > svg {
    margin-left: 6px;
  }

  > span {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
  }

  @media (max-width: 1280px) {
    width: 180px;
  }
`;

const LootItemsContainer = styled.div`
  padding: 24px 48px 44px 48px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 310px));
  grid-gap: 23px;
  min-height: 375px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 280px));
  }
  @media (max-width: 1366px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 270px));
  }
  @media (max-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  }
`;

const LootTextAndAchieve = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LootText = styled.div`
  max-width: 508px;

  h4 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
    margin-top: 8px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

const LootAchieves = styled.div`
  display: flex;
  align-items: center;
`;
