import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import smallDiamond from "../../../assets/img/smallDiamond.svg";
import { usePostBuyLootbox } from "../../../hooks/usePostBuyLootbox";
import { imageRoute } from "../../../utils/api";
import { EpickBtn } from "../Button/EpickBtn";
import { Modal } from "../Modal";

type LootItemPropsType = {
  id: number;
  rarity: string;
  name: string;
  price: number;
  src: string;
  disabled: boolean;
  claimable: boolean;
  canBeOpened: boolean;
  onSale: boolean;
};

export const Lootbox = (props: LootItemPropsType) => {
  const colorRarity = props.rarity.toLocaleLowerCase();
  const { mutate: buyLootbox } = usePostBuyLootbox();
  const [showConfirmBuyModal, setShowConfirmBuyModal] = useState<boolean>(false);
  const [showDisabledModal, setShowDisabledModal] = useState<boolean>(false);

  return (
    <>
      <LootItemWrapper
        color={colorRarity}
        dis={props.disabled}
        onClick={() => {
          if (!props.canBeOpened && !props.onSale) setShowDisabledModal(true);
          else setShowConfirmBuyModal(true);
        }}
      >
        <NavLink to={!props.disabled && props.canBeOpened ? `/loot/${props.id}` : `#`}>
          <LootItemBg>
            <EpickBtn onclick={() => {}} color={colorRarity}>
              {props.rarity}
            </EpickBtn>
          </LootItemBg>
          <LootItemText>
            <h3>{props.name}</h3>
            {props.src.length === 0 ? <div>No Photo</div> : <img src={`${imageRoute}lootboxes/${props.src}`} alt="" />}
            <CristalCount>
              <span>BUY FOR {props.price} </span>
              <img src={smallDiamond} alt="" />
            </CristalCount>
          </LootItemText>
        </NavLink>
      </LootItemWrapper>
      <Modal visible={showConfirmBuyModal} onClose={() => setShowConfirmBuyModal(false)}>
        <ModalContent>
          <h3 style={{ fontSize: "20px", padding: "0 20px" }}>
            This lootbox cannot be opened yet.
            <br /> Do you want to buy it?
          </h3>
          <div style={{ marginBottom: "20px" }}>
            <CristalCount>
              <span>Price: {props.price} </span>
              <img src={smallDiamond} alt="" />
            </CristalCount>
          </div>
          <ModalButton style={{ marginBottom: "20px" }}>
            <AcceptSell
              onClick={() => {
                buyLootbox({ lootboxId: +props.id, quantity: 1 });
                setShowConfirmBuyModal(false);
              }}
            >
              Yes
            </AcceptSell>
            <NotSell onClick={() => setShowConfirmBuyModal(false)}>No</NotSell>
          </ModalButton>
        </ModalContent>
      </Modal>
      <Modal visible={showDisabledModal} onClose={() => setShowDisabledModal(false)}>
        <ModalContent>
          <h3 style={{ fontSize: "20px", marginBottom: "32px", padding: "0 20px" }}>This lootbox cannot be opened yet and is currently not on sale.</h3>
        </ModalContent>
      </Modal>
    </>
  );
};

const LootItemWrapper = styled.div<{
  color: string;
  dis: boolean;
}>`
  height: 375px;
  width: 310px;
  position: relative;

  :hover {
    ${props => (!props.dis ? `cursor: pointer;` : `cursor: no-drop;`)}
  }

  :hover img {
    ${props =>
      !props.dis
        ? `cursor: pointer;transform: scale(1.05);
    transition: 0.2s transform;`
        : `cursor: no-drop;`}
  }

  img {
    transition: 0.2s transform;
  }

  ${props => (!props.dis ? `opacity: 1;` : `opacity: 0.5;`)}
`;

const LootItemBg = styled.div`
  width: 310px;
  height: 310px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-end;

  > button {
    margin-bottom: 40px;
    z-index: 1;
  }
`;

const LootItemText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  }

  > img {
    margin-top: 6px;
    position: absolute;
    top: 0;
  }
`;

const CristalCount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  > span {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #ffd63d;
  }

  > img {
    margin-left: 6px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 442px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  > div {
    padding: 8px 18px;
    border-radius: 8px;
    margin: 0 25px;
    :hover {
      cursor: pointer;
    }
  }
`;

const AcceptSell = styled.div`
  background: green;
  color: #fff;
`;
const NotSell = styled.div`
  background: red;
  color: #fff;
`;
