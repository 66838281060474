import { useState } from "react";
import styled from "styled-components";
import { useAdminGetAllLootboxes } from "../../../hooks/useAdminGetAllLootboxes";
import { imageRoute } from "../../../utils/api";
import { Lootbox } from "../../../utils/commonTypes";
import { Pagination } from "../Pagination";
import { useHistory } from "react-router-dom";

export const LootboxPicker = (props: { onPick: (lootbox: Lootbox) => void }) => {
  const [page, setPage] = useState<number>(1);
  const { data: lootboxesData, isLoading: isGetLoadingAdminLootBox } = useAdminGetAllLootboxes({
    limit: 10,
    maxPrice: 10000000,
    minPrice: 0,
    page: page
  });
  const history = useHistory();

  const lootboxes = lootboxesData?.data.rows || [];
  return (
    <LootboxWrapper>
      <Header>Lootboxes</Header>
      {lootboxes.map(lootbox => (
        <LootboxDiv key={lootbox.id}>
          <div className="imgDiv">{!lootbox.image ? <span>No image</span> : <img src={`${imageRoute}lootboxes/${lootbox.image}`} alt="" />}</div>
          <span>{lootbox.name}</span>
          <div className="actionButtons">
            <div className="action" onClick={() => history.push(`/admin/lootbox/1/case/${lootbox.id}`)}>
              See items
            </div>
            <div className="action" onClick={() => props.onPick(lootbox)}>
              Pick
            </div>
          </div>
        </LootboxDiv>
      ))}
      {lootboxesData?.data.rows.length ? (
        <PaginationWrapper>
          <Pagination setPageNumber={setPage} pageNumber={page} itemsPerPage={10} itemsCount={lootboxesData.data.count} />
        </PaginationWrapper>
      ) : (
        ""
      )}
    </LootboxWrapper>
  );
};

const Header = styled.h1`
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LootboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const LootboxDiv = styled.div`
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid #232323;
  padding-bottom: 15px;
  border-radius: 9px;
  cursor: pointer;
  position: relative;

  .actionButtons {
    height: 0;
    width: 100%;
    transition: 0.2s all;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #29efa8;
    color: #0e141a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .action {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #1ec187;
    }

    &:first-child {
      border-bottom: 1px solid #0e141a;
    }
  }

  &:hover {
    border-color: #29efa8;

    .actionButtons {
      height: 30%;
    }
  }

  .imgDiv {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  span {
    text-align: center;
  }
`;
