import { after } from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as PaginationIcon } from "../../../assets/img/paginationArrow.svg";

type PaginationPropsType = {
  pageNumsToShow?: number;
  pageNumber: number;
  itemsCount: number;
  itemsPerPage: number;
  setPageNumber: (num: number) => void;
};

export const Pagination = (props: PaginationPropsType) => {
  const currentPage = props.pageNumber;
  const totalPages = Math.ceil(props.itemsCount / props.itemsPerPage);

  const plusDisabled = currentPage >= totalPages;
  const decDisabled = currentPage <= 1;

  const plusPage = () => {
    !plusDisabled && props.setPageNumber(currentPage + 1);
  };

  const decPage = () => {
    !decDisabled && props.setPageNumber(currentPage - 1);
  };

  const pageNumsToShow = props.pageNumsToShow ?? 7;
  const maxConsecutive = pageNumsToShow - 2;

  let toShow: any[] = [];

  if (totalPages <= pageNumsToShow) {
    for (let i = 1; i <= totalPages; i++)
      toShow.push(
        i == currentPage ? (
          <PageNumber key={i + 10000}>{currentPage}</PageNumber>
        ) : (
          <ExtraPageNumber key={i + 10000} onClick={() => props.setPageNumber(i)}>
            {i}
          </ExtraPageNumber>
        )
      );
  } else {
    if (currentPage < maxConsecutive) {
      for (let i = 1; i <= maxConsecutive; i++)
        toShow.push(
          i == currentPage ? (
            <PageNumber key={i + 20000}>{currentPage}</PageNumber>
          ) : (
            <ExtraPageNumber key={i + 20000} onClick={() => props.setPageNumber(i)}>
              {i}
            </ExtraPageNumber>
          )
        );
      toShow.push(
        ...[
          <ExtraPageNumber key={"threedots1"} onClick={() => props.setPageNumber(currentPage + Math.floor((totalPages - currentPage) / 2))}>
            ...
          </ExtraPageNumber>,
          <ExtraPageNumber key={"totalpages1"} onClick={() => props.setPageNumber(totalPages)}>
            {totalPages}
          </ExtraPageNumber>
        ]
      );
    } else if (totalPages - currentPage < maxConsecutive) {
      toShow.push(
        ...[
          <ExtraPageNumber key={"firstpage1"} onClick={() => props.setPageNumber(1)}>
            {1}
          </ExtraPageNumber>,
          <ExtraPageNumber key={"threedots2"} onClick={() => props.setPageNumber(Math.ceil(currentPage / 2))}>
            ...
          </ExtraPageNumber>
        ]
      );
      for (let i = totalPages - maxConsecutive; i <= totalPages; i++)
        toShow.push(
          i == currentPage ? (
            <PageNumber key={i + 30000}>{currentPage}</PageNumber>
          ) : (
            <ExtraPageNumber key={i + 30000} onClick={() => props.setPageNumber(i)}>
              {i}
            </ExtraPageNumber>
          )
        );
    } else {
      toShow.push(
        ...[
          <ExtraPageNumber key={"firstpage2"} onClick={() => props.setPageNumber(1)}>
            {1}
          </ExtraPageNumber>,
          <ExtraPageNumber key={"threedots3"} onClick={() => props.setPageNumber(Math.ceil(currentPage / 2))}>
            ...
          </ExtraPageNumber>
        ]
      );
      const beforeCurrent = Math.floor((pageNumsToShow - 4) / 2);
      const afterCurrent = pageNumsToShow - 4 - beforeCurrent;
      for (let i = currentPage - beforeCurrent; i <= currentPage + afterCurrent - 1; i++)
        toShow.push(
          i == currentPage ? (
            <PageNumber key={i + 40000}>{currentPage}</PageNumber>
          ) : (
            <ExtraPageNumber key={i + 40000} onClick={() => props.setPageNumber(i)}>
              {i}
            </ExtraPageNumber>
          )
        );
      toShow.push(
        ...[
          <ExtraPageNumber key={"threedots4"} onClick={() => props.setPageNumber(currentPage + Math.floor((totalPages - currentPage) / 2))}>
            ...
          </ExtraPageNumber>,
          <ExtraPageNumber key={"totalpages2"} onClick={() => props.setPageNumber(totalPages)}>
            {totalPages}
          </ExtraPageNumber>
        ]
      );
    }
  }

  return (
    <PaginationContent>
      <PaginationArrowLeft onClick={decPage} disabled={decDisabled} />
      {toShow}
      <PaginationArrowRight onClick={plusPage} disabled={plusDisabled} />
    </PaginationContent>
  );
};

const PaginationContent = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationArrowLeft = styled(PaginationIcon)<{ disabled: boolean }>`
  fill: #616d7a;

  :hover {
    fill: #29efa8;
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    `
  :hover {
    fill: #616d7a;
    cursor: not-allowed;
  }
  `}
`;

const PaginationArrowRight = styled(PaginationIcon)<{ disabled: boolean }>`
  fill: #616d7a;
  transform: rotate(180deg);

  :hover {
    fill: #29efa8;
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    `
  :hover {
    fill: #616d7a;
    cursor: not-allowed;
  }
  `}
`;

const PageNumber = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 12px 16px;
  border: 2px solid #29efa8;
  border-radius: 6px;
  margin: 0 15px;
`;

const ExtraPageNumber = styled(PageNumber)`
  border: 2px solid rgba(0, 0, 0, 0);
  color: #616d7a;

  :hover {
    cursor: pointer;
    border: 2px solid #29efa8;
    color: #fff;
  }
`;

const DotsSpan = styled.span``;
