import styled from "styled-components";
import { ReactComponent as BGItemL } from "../../assets/img/landingBGItemLeft.svg";
import { ReactComponent as BGItemR } from "../../assets/img/landingBGItemRight.svg";
import passBlueItem from "../../assets/img/blueTiket.png";
import petItem from "../../assets/img/goldTiket.png";
import passItem from "../../assets/img/purpleTiket.png";
import { ReactComponent as EllipseRob } from "../../assets/img/ellipseMarket.svg";
import bgItem from "../../assets/img/landingEffectItem.svg";
import { TedButton } from "../common/Button/TedButton";
import { useState, useContext } from "react";
import Select from "react-select";
import { customStylesForCountrySelect } from "../../utils/commonFunctions";
import { Modal } from "../../components/common/Modal";
import { ethers } from "ethers";
import { redeemTicket, claimMIP, getTicketBalance, getTicketsBalance, getRemainingClaimCount } from "../../metamask/contracts";
import { CustomLoader } from "../common/Loader";
import { GlobalContext, GlobalContextType } from "../../App";
import axios from "axios";
import { apiRoute } from "../../utils/api";
import authHeader from "../../utils/auth-header";
import petTicketVideo from "../../assets/videos/petticket.webm";
import bodyTicketVideo from "../../assets/videos/bodyticket.webm";
import featureShadow3 from "../../assets/img/featureshadow3.webp";
import { useMountedLayoutEffect } from "react-table";

const countries: any = require("../../utils/countries-and-cities.json");

type PropsTypeLanding = {
  type: "head" | "pass" | "pet" | "body";
  ticketId?: number;
  disable?: boolean;
};

export function LandingItem(props: PropsTypeLanding) {
  const { setAlertModal } = useContext(GlobalContext) as GlobalContextType;
  const [redeemModal, setRedeemModal] = useState<{
    visible: boolean;
    country?: string | null;
    countryCode?: string | null;
    city?: string | null;
    addressConfirmed: boolean;
    redeem_amount: number | null;
    errors: { country: boolean; city: boolean; redeem_amount: boolean };
  }>({
    country: null,
    countryCode: null,
    city: null,
    visible: false,
    addressConfirmed: false,
    redeem_amount: 1,
    errors: { country: false, city: false, redeem_amount: false }
  });

  const [balances, setBalances] = useState<{ [ticketName: string]: number }>({});
  const [remainingClaimCount, setRemainingClaimCount] = useState<number>(0);
  const [chosenOption, setChosenOption] = useState<string>("Body Ticket");
  const [processing, setProcessing] = useState<boolean>(false);

  const connectWalletAndGetUserAddress = async () => {
    if (!(window as any).ethereum) throw new Error("Install Metamask in order to log in.");

    await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const network = await provider.getNetwork();
    if (network.chainId != +process.env.REACT_APP_ETH_CHAIN_ID!) {
      setAlertModal({
        visible: true,
        message: "Please change Metamask network to " + process.env.REACT_APP_ETH_CHAIN_NAME,
        type: "error"
      });
      return;
    }
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const ticketsBalance = await getTicketsBalance(address, [
      Number(process.env.REACT_APP_BODY_TICKET_ID!),
      Number(process.env.REACT_APP_GOLD_BODY_TICKET_ID!),
      Number(process.env.REACT_APP_PET_TICKET_ID!)
    ]);

    setRemainingClaimCount(await getRemainingClaimCount(address));

    setBalances({
      "Body Ticket": Number(ticketsBalance[process.env.REACT_APP_BODY_TICKET_ID!]),
      "Gold Body Ticket": Number(ticketsBalance[process.env.REACT_APP_GOLD_BODY_TICKET_ID!]),
      "Pet Ticket": Number(ticketsBalance[process.env.REACT_APP_PET_TICKET_ID!])
    });

    setRedeemModal({ ...redeemModal, visible: true });
  };

  const confirmAddress = () => {
    if (!redeemModal.country || !redeemModal.city || !redeemModal.redeem_amount || isNaN(redeemModal.redeem_amount)) {
      setRedeemModal({
        ...redeemModal,
        errors: {
          country: !redeemModal.country,
          city: !redeemModal.city,
          redeem_amount: !redeemModal.redeem_amount || isNaN(redeemModal.redeem_amount)
        }
      });
      return;
    }

    setRedeemModal({
      ...redeemModal,
      addressConfirmed: true,
      errors: { country: false, city: false, redeem_amount: false }
    });

    activateTicket();
  };

  const activateTicket = async () => {
    let ticket_id;
    if (chosenOption == "Body Ticket") ticket_id = process.env.REACT_APP_BODY_TICKET_ID!;
    else if (chosenOption == "Gold Body Ticket") ticket_id = process.env.REACT_APP_GOLD_BODY_TICKET_ID!;
    else if (chosenOption == "Pet Ticket") ticket_id = process.env.REACT_APP_PET_TICKET_ID!;
    else ticket_id = "-1";

    if (!(window as any).ethereum) throw new Error("Install Metamask in order to log in.");

    const accounts = await (window as any).ethereum.request({
      method: "eth_requestAccounts"
    });
    const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    await axios.post(
      `${apiRoute}/users/redeemTicketInit`,
      {
        wallet: address,
        ticket_id: ticket_id,
        amount: redeemModal.redeem_amount,
        country: redeemModal.country,
        city: redeemModal.city
      },
      {
        headers: authHeader()
      }
    );
    setProcessing(true);
    const result =
      chosenOption == "Claim Body" ? await claimMIP(address, Number(process.env.REACT_APP_MIP_TYPE_BODY!)) : await redeemTicket(address, ticket_id, redeemModal.redeem_amount!);
    setProcessing(false);
    if (!result.success) {
      setAlertModal({
        message: result.message ?? "An error occurred.",
        visible: true,
        type: "error"
      });
      return;
    }
    setRedeemModal({ ...redeemModal, visible: false });
    await axios.post(
      `${apiRoute}/users/redeemTicket`,
      {
        wallet: address,
        ticket_id: ticket_id,
        amount: redeemModal.redeem_amount,
        country: redeemModal.country,
        city: redeemModal.city,
        transactionHash: result.tx.transactionHash
      },
      {
        headers: authHeader()
      }
    );

    setAlertModal({
      message: `You successfully redeemed ${redeemModal.redeem_amount} MIP ${
        redeemModal.redeem_amount == 1 ? (chosenOption.includes("Body") ? "Body" : "Pet") : chosenOption.includes("Body") ? "Bodies" : "Pets"
      }`,
      visible: true,
      type: "success"
    });
  };

  return (
    <>
      <HeadItem>
        <RobotItemWrap>
          {/* {props.type === "pass" ? <img src={passBlueItem} alt="" /> : props.type === "body" ? <img src={passItem} alt="" /> : <img src={petItem} alt="" />} */}
          {["pet", "body"].includes(props.type) && (
            <video autoPlay loop muted playsInline preload="auto" controls={false} style={{ maxWidth: "100%", position: "absolute", zIndex: 1 }}>
              <source src={props.type == "pet" ? petTicketVideo : bodyTicketVideo} type="video/webm" />
            </video>
          )}

          <EllipceRobot type={props.type} />
          <EllipceRobot2 />
        </RobotItemWrap>

        <TextItemWrap>
          <h4>MINT</h4>
          <h2>{props.type === "pass" ? "PREMIUM PASS" : props.type === "body" ? "BODY" : "MIP Pet Redeem"}</h2>
          <p>Exchange of MIP Body Tickets</p>

          <ButtonWrap>
            <TedButton color="blue" onclick={connectWalletAndGetUserAddress} size="big" width="192" children="ACTIVATE TICKET" active={true} disabled={props.disable} />
          </ButtonWrap>
        </TextItemWrap>

        <BGItemLeftTop />
        <BGItemLeftBot />
        <BGItemRightBot />
        <BGItemRightTop />
      </HeadItem>
      {redeemModal.visible && (
        <Modal visible={redeemModal.visible} center={false}>
          <ModalContent>
            {processing && (
              <LoaderDiv>
                <CustomLoader />
                Please wait ...
              </LoaderDiv>
            )}
            {!processing && (
              <>
                <h3>Please enter you location: </h3>
                <br />
                <SelectWrapper style={{ width: "361px", margin: "15px 0 0 0", maxWidth: "100%" }}>
                  <Select
                    value={
                      redeemModal.country
                        ? {
                            countryCode: redeemModal.countryCode,
                            value: redeemModal.country,
                            label: redeemModal.country
                          }
                        : null
                    }
                    onChange={(e: any) =>
                      setRedeemModal({
                        ...redeemModal,
                        country: e?.value || null,
                        countryCode: e?.countryCode || null,
                        city: null
                      })
                    }
                    options={Object.keys(countries).map(key => ({
                      value: countries[key].name,
                      label: countries[key].name,
                      countryCode: key
                    }))}
                    styles={customStylesForCountrySelect}
                    placeholder="Select country..."
                  />
                </SelectWrapper>
                {redeemModal.errors.country && <ErrorMsg>Please select a country</ErrorMsg>}
                <SelectWrapper style={{ width: "361px", margin: "15px 0 0 0", maxWidth: "100%" }}>
                  <Select
                    value={
                      redeemModal.city
                        ? {
                            value: redeemModal.city,
                            label: redeemModal.city
                          }
                        : null
                    }
                    onChange={(e: any) =>
                      setRedeemModal({
                        ...redeemModal,
                        city: e?.value || null
                      })
                    }
                    options={(redeemModal.countryCode ? (countries[redeemModal.countryCode].cities as [string]) : []).map(city => ({
                      value: city,
                      label: city
                    }))}
                    styles={customStylesForCountrySelect}
                    placeholder="Select city..."
                  />
                </SelectWrapper>
                {redeemModal.errors.city && <ErrorMsg>Please select a city</ErrorMsg>}
                <SelectWrapper style={{ width: "361px", margin: "15px 0 0 0", maxWidth: "100%" }}>
                  <Select
                    value={{
                      value: chosenOption,
                      label: chosenOption
                    }}
                    onChange={(e: any) => setChosenOption(e.value)}
                    options={[
                      { value: "Body Ticket", label: "Body Ticket" },
                      { value: "Gold Body Ticket", label: "Gold Body Ticket" },
                      { value: "Claim Body", label: "Claim Body" }
                      // { value: "Pet Ticket", label: "Pet Ticket" }
                    ]}
                    styles={customStylesForCountrySelect}
                    placeholder="Select redeem option"
                  />
                </SelectWrapper>
                <InputWrapper>
                  <Input
                    type="text"
                    value={redeemModal.redeem_amount ?? ""}
                    onChange={(e: any) => {
                      console.log(isNaN(e.target.value), e.target.value);
                      if (isNaN(e.target.value) || +e.target.value < 1) {
                        setRedeemModal({ ...redeemModal, redeem_amount: null });
                        e.preventDefault();
                        return;
                      }
                      if (chosenOption != "Claim Body" && +e.target.value > balances[chosenOption]) {
                        e.preventDefault();
                        return;
                      }
                      setRedeemModal({ ...redeemModal, redeem_amount: e.target.value });
                    }}
                    placeholder="Amount to redeem"
                    style={{ border: "none", width: "100%", textAlign: "left", fontSize: "14px", paddingLeft: "13px" }}
                  />
                </InputWrapper>
                {redeemModal.errors.redeem_amount && <ErrorMsg>Please enter a valid redeem amount</ErrorMsg>}
                {chosenOption != "Claim Body" && (
                  <p style={{ marginTop: "15px", width: "100%", textAlign: "center", fontSize: "14px", color: "#636d79" }}>
                    You own <span style={{ color: "#25E79F" }}>{balances[chosenOption]}</span> ticket(s)
                  </p>
                )}
                {chosenOption == "Claim Body" && (
                  <p style={{ marginTop: "15px", width: "100%", textAlign: "center", fontSize: "14px", color: "#636d79" }}>
                    You have <span style={{ color: "#25E79F" }}>{remainingClaimCount}</span> claim(s) remaining
                  </p>
                )}
                <div>
                  <ModalButton style={{ marginRight: "10px", display: "inline-flex" }}>
                    <TedButton
                      disabled={(chosenOption != "Claim Body" && balances[chosenOption] == 0) || (chosenOption == "Claim Body" && remainingClaimCount == 0)}
                      size="modal"
                      width="100"
                      color="green"
                      onclick={confirmAddress}
                    >
                      Ok
                    </TedButton>
                  </ModalButton>
                  <ModalButton style={{ marginLeft: "10px", display: "inline-flex" }}>
                    <TedButton
                      size="modal"
                      width="100"
                      color="yellow"
                      onclick={() => {
                        setRedeemModal({
                          ...redeemModal,
                          visible: false,
                          addressConfirmed: false
                        });
                      }}
                    >
                      Cancel
                    </TedButton>
                  </ModalButton>
                </div>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  text-align: left;
  margin-top: 7px;
  font-size: 13px;
  margin-left: 50px;
`;

const LoaderDiv = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const InputWrapper = styled.div`
  width: 361px;
  max-width: 100%;
  height: 46px;
  background: #161d24;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 12px;
`;

const Input = styled.input`
  background: none !important;
  outline: none !important;
  border: none !important;
  color: #fff !important;
  width: 100% !important;

  ::placeholder {
    color: #616d7a; !important
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 742px;
  padding: 30px 30px;
  max-width: 90%;
  box-sizing: border-box;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const SelectWrapper = styled.div`
  width: 192px;
  height: 46px;
  margin-left: 12px;
  margin-bottom: 12px;

  @media (max-width: 980px) {
    margin: 0;
    width: 35%;
  }

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
    width: 100%;
  }
`;

const TextItemWrap = styled.div`
  text-align: center;
  color: #a7b0cb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    margin: 8px 0 16px 0;
    font-size: 32px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
    font-family: Glitch;
  }

  > h4 {
    font-size: 16px;
    line-height: 16px;
    margin-top: 40px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }
`;

const ButtonWrap = styled.div`
  margin: 20px 0 32px 0;
  display: flex;

  @media (max-width: 375px) {
    align-items: center;
  }

  > div {
    max-width: 192px;
    @media (max-width: 1280px) {
      max-width: 140px;
    }
  }
`;

const RobotItemWrap = styled.div`
  margin-right: 30px;
  position: absolute;

  height: 100px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  top: -20px;

  > img {
    z-index: 1;
    @media (max-width: 1280px) {
      height: 310px;
      margin-top: -60px;
    }
  }
`;

const EllipceRobot = styled(EllipseRob)`
  fill: #f4e458;
  position: absolute;
  top: -60px;
`;

const BGItemLeftTop = styled(BGItemL)`
  position: absolute;
  left: -3px;
  top: -3px;
  fill: #f4e458;
`;
const BGItemRightBot = styled(BGItemL)`
  position: absolute;
  right: -3px;
  bottom: -3px;
  transform: rotate(180deg);
  fill: #f4e458;
`;

const BGItemLeftBot = styled(BGItemR)`
  position: absolute;
  left: -3px;
  bottom: -3px;
  fill: #f4e458;
`;

const BGItemRightTop = styled(BGItemR)`
  position: absolute;
  right: -3px;
  top: -3px;
  transform: rotate(180deg);
  fill: #f4e458;
`;

const EllipceRobot2 = styled(EllipseRob)`
  fill: #78f7ff;
  position: absolute;
  height: 160px;
  top: -60px;
`;

const HeadItem = styled.div`
  // background-image: url(${bgItem});
  background: #00000099;
  width: 494px;
  height: 368px;
  position: relative;
  border: 1px solid #234b6b;
  margin-top: 95px;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;

  :nth-child(2n) {
    margin: 0 40px 95px 40px;
    @media (max-width: 1280px) {
      margin: 0 20px 75px 20px;
    }
  }
  fill: #ffc155;

  @media (max-width: 1600px) {
    width: 460px;
    height: 415px;
  }
  @media (max-width: 1500px) {
    width: 440px;
    height: 415px;
  }
  @media (max-width: 1430px) {
    width: 410px;
    height: 400px;
  }
  @media (max-width: 1340px) {
    width: 390px;
    height: 400px;
  }
  @media (max-width: 1280px) {
    width: 365px;
    height: 315px;
  }
  @media (max-width: 1180px) {
    width: 440px;
    height: 375px;
  }
  @media (max-width: 820px) {
    width: 440px;
    height: 310px;
  }
  @media (max-width: 480px) {
    width: 400px;
  }
  @media (max-width: 414px) {
    width: 368px;
  }
  @media (max-width: 375px) {
    width: 300px;
  }
`;
