import styled from "styled-components/macro";
import { useState, useEffect } from "react";
import { useGetParts } from "../../../hooks/useGetParts";
import { useGetParamsForDetailsFilter } from "../../../hooks/useGetParamsForDetailsFilter";
import { Pagination } from "../Pagination";
import { customStylesForGarageSelect } from "../../../utils/commonFunctions";
import Select from "react-select";
import { ReactComponent as SearchIcon } from "../../../assets/img/search.svg";
import { IFilter } from "../../../utils/commonInterfaces";
import { ParamsForDetailsFilter } from "../../../utils/commonTypes";
import { CustomLoader } from "../Loader";

type FilterProps = {
  currentUser?: boolean;
  header?: string;
  onPartsUpdate?: Function;
  children?: React.ReactNode;
};

export const Filters = (props: FilterProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState<object>({});

  const sectionCategories: any = {
    MIPHead: ["Eyebrows", "Mask", "Fin", "Shell", "Jetpack", "Thrusters", "Ears", "Eyes"],
    Driver: ["Hair", "Head", "Eyes", "Body", "Hands", "Pout", "Dizzy"],
    Pet: ["Body", "Ext", "Head", "Jetpack", "Tail"]
  };

  const { data: parts, isLoading: isPartsLoading } = useGetParts({
    current_user: props.currentUser ?? false,
    page: pageNumber,
    filters: selectedFilters
  });

  useEffect(() => {
    if (props.onPartsUpdate) props.onPartsUpdate(parts?.data.result);
  }, [parts]);

  const { data: filterParams } = useGetParamsForDetailsFilter();

  const getFilterOptions = (filterName: string) => {
    const emptyFilter = {
      value: "",
      label: `No ${filterName} filter`
    };
    const filterParamsArr = filterParams?.data[filterName as keyof ParamsForDetailsFilter]
      .filter((item: any) => {
        if (filterName === "category") {
          const selectedSection = selectedFilters["section" as keyof object];
          return !selectedSection || sectionCategories[selectedSection].includes(item);
        }
        return true;
      })
      .map((item: any) => {
        return {
          value: item,
          label: item
        };
      });
    return filterParamsArr ? [emptyFilter, ...filterParamsArr] : [emptyFilter];
  };

  const changeFilter = (filters: IFilter[]) => {
    const filterCopy: any = { ...selectedFilters };

    for (let filter of filters) {
      const { filterName, value } = filter;
      if (value === filterCopy[filterName]) continue;
      if (!value) delete filterCopy[filterName];
      else filterCopy[filterName] = value;

      if (filterName === "section" && !sectionCategories[value]?.includes(filterCopy["category"])) delete filterCopy["category"];
    }

    setPageNumber(1);
    setSelectedFilters(filterCopy);
  };

  return (
    <PartsWrapper>
      <PartsTools>
        <PartFilter>
          <h3>{props.header ?? "Parts"}</h3>
          <InputWrapper>
            <SearchIcon />
            <Input
              value={searchText}
              onKeyDown={(e: any) => {
                if (e.key === "Enter")
                  changeFilter([
                    {
                      filterName: "searchText",
                      value: e.currentTarget.value
                    }
                  ]);
              }}
              onBlur={e => {
                changeFilter([
                  {
                    filterName: "searchText",
                    value: e.currentTarget.value
                  }
                ]);
              }}
              onChange={(e: any) => setSearchText(e.currentTarget.value)}
              placeholder="Enter your search"
            />
          </InputWrapper>
        </PartFilter>
        <FilterSelect>
          {Object.keys(filterParams?.data || {}).map((filterName, i) => (
            <SelectWrapper key={i}>
              <Select
                value={
                  selectedFilters[filterName as keyof object]
                    ? {
                        value: selectedFilters[filterName as keyof object],
                        label: selectedFilters[filterName as keyof object]
                      }
                    : null
                }
                onChange={(e: any) => changeFilter([{ filterName, value: e?.value || null }])}
                options={getFilterOptions(filterName)}
                styles={customStylesForGarageSelect}
                placeholder={`${filterName.toUpperCase()}`}
              />
            </SelectWrapper>
          ))}
        </FilterSelect>
      </PartsTools>
      <Parts id="Parts">{isPartsLoading ? <CustomLoader margin="20px 0 0 30px;" /> : !parts?.data.result.length ? <div>No parts found</div> : <>{props.children}</>}</Parts>
      {(parts?.data.count || 0) > 16 ? (
        <PaginationWrapper>
          <Pagination setPageNumber={setPageNumber} pageNumber={pageNumber} itemsPerPage={21} itemsCount={parts?.data.count || 0} />
        </PaginationWrapper>
      ) : (
        ""
      )}
    </PartsWrapper>
  );
};

const PartsWrapper = styled.div`
  margin: 27px 0 0 0;
  padding-bottom: 32px;
  width: 100%;
`;

const PartsTools = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 38px;
  }
`;

const Parts = styled.div`
  margin: 20px 0 0 30px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 446px) {
    margin: 20px 15px;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectWrapper = styled.div`
  width: 192px;
  height: 46px;
  margin-left: 12px;
  margin-bottom: 12px;

  @media (max-width: 980px) {
    margin: 0;
    width: 35%;
  }

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
    width: 100%;
  }
`;

const FilterSelect = styled.div`
  margin-right: 48px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 980px) {
    width: calc(100% - 15px);
    justify-content: space-between;
    margin-right: 0;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: normal;
  }
`;

const PartFilter = styled.div`
  display: flex;
  margin-left: 48px;

  > h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 361px;
  height: 46px;
  background: #161d24;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  border: none;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 12px;

  @media (max-width: 1150px) {
    width: auto;
  }

  @media (max-width: 980px) {
    margin-left: 0;
    margin-top: 15px;
    width: calc(100% - 15px);
  }
`;

const Input = styled.input`
  margin-left: 12px;
  background: none;
  outline: none;
  border: none;
  color: #fff;
  width: 100%;

  ::placeholder {
    color: #616d7a;
  }
`;
