import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";

export const MetaMaskAuth = () => {
  return (
    <ConnectWrapper>
      <ConnectButton onClick={() => {}}>Login via MetaMask</ConnectButton>;
    </ConnectWrapper>
  );
};

const AddressSpan = styled.span`
  background-color: #e5771b;
  color: black;
  padding: 5px;
  border-radius: 5px;
  border: none;
`;

const ConnectButton = styled.button`
  color: white;
  background: -webkit-linear-gradient(left, #e5771b, #753d16);
  background-size: 200% 200%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  border-width: 1px;
  padding: 13px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2em;

  :hover {
    background: -webkit-linear-gradient(left, #e5771b, #e5771b);
  }
`;

const ConnectWrapper = styled.div`
  width: 100%;
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    color: #fff;
  }

  p {
    margin-top: 10px;
  }
`;
