import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import { ProfileDataType } from "../utils/commonTypes";

function useGetProfile(token: string | null) {
  const { data, isLoading } = useQuery(
    ["getProfile", token],
    () =>
      axios
        .get<ProfileDataType>(`${apiRoute}/users/profile`, {
          headers: { authtoken: token }
        })
        .then(x => x.data),
    {
      enabled: !!token,
      onError: () => {
        localStorage.clear();
      }
    }
  );

  return { data, isLoading };
}

export { useGetProfile };
