import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Lootbox } from "../utils/commonTypes";

function useAdminGetLootbox(lootboxId: number) {
  const { data, isLoading } = useQuery(
    ["getAdminLootBox", lootboxId],
    () =>
      axios.get<Lootbox>(`${apiRoute}/lootboxes/${lootboxId}`, {
        headers: authHeader(),
      }),
    { enabled: !!lootboxId },
  );

  return { data, isLoading };
}

export { useAdminGetLootbox };
