import styled from "styled-components";
import { ReactComponent as CircleIcon } from "../../../assets/img/modalBGItemSpin.svg";
import { TedButton } from "../Button/TedButton";
import Slider from "react-slick";
import { LootboxItem } from "../../../utils/commonTypes";
import { SlickArrowLeftProfile, SlickArrowRightProfile } from "../SlickArrow/SlickArrow";
import lootItemBorderImg from "../../../assets/img/lootItemBorder.svg";
import { LootItem } from "../LootItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type PropsTypeWinSpin = {
  wonItems: LootboxItem[];
  onclose: () => void;
  children?: React.ReactNode;
};

export function ModalMultiWinnerSpin({ onclose, wonItems, children }: PropsTypeWinSpin) {
  console.log("items won", wonItems);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: wonItems?.length > 1 || false,
    centerPadding: "70px"
  };

  const afterChangeHandler = (currentslide: number) => {
    //setActiveElForSeil(wonItem[currentSlide]);
  };

  return (
    <ModalWrapper>
      <h3>Congratulations!</h3>
      <img src={lootItemBorderImg} alt="" />

      <SliderWrap {...settings} nextArrow={<SlickArrowRightProfile1 />} prevArrow={<SlickArrowLeftProfile2 />} afterChange={afterChangeHandler}>
        {wonItems.map((item: LootboxItem, idx: number) => (
          <LootItem key={idx} item={item} settings={{ height: "250px", hideBottomBorder: true, hidePrice: true, hideBackground: true, tier: { hideRight: true } }} />
        ))}
      </SliderWrap>
      <ModalButton style={{ marginTop: "40px", marginBottom: "10px" }}>
        <TedButton size="medium" width="160" color="green" onclick={() => onclose()}>
          Ok
        </TedButton>
        <TedButton size="medium" width="160" color="blue" active={true} onclick={() => {}}>
          {`$ Sell for 66 MIP`}
        </TedButton>
      </ModalButton>
    </ModalWrapper>
  );
}

const ImgWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderWrap = styled(Slider)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 257px;
  width: 370px;

  .slick-dots button:before {
    color: white !important;
  }
`;

const SlickArrowRightProfile1 = styled(SlickArrowRightProfile)`
  position: absolute;
  right: 0;
`;

const SlickArrowLeftProfile2 = styled(SlickArrowLeftProfile)`
  position: absolute;
  margin-top: 4px;
  left: 0;
`;

const ModalWrapper = styled.div`
  width: 400px;
  display: flex;
  height: 410px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h3 {
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fff;
  }

  > img {
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 30px;

  > div {
    margin: 0 6px;
  }
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  fill: #29efa8;
  width: 250px;
`;

const RobotModal = styled.img`
  height: 180px;
  width: 180px;
  z-index: 2;
`;

const BgRobotModal = styled.img`
  position: absolute;
  height: 170px;
`;

const TextModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  text-align: center;
  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const ItemModal = styled.div`
  width: 183px;
  height: 243px;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;

  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;
