import styled from "styled-components";
import { ReactComponent as ArrowHorizontItem } from "../../../assets/img/bonusesHorArrow.svg";
import { ReactComponent as CircleIcon } from "../../../assets/img/ellipseMarket.svg";
import dateIcon from "../../../assets/img/bonusesDateIcon.svg";
import smallDiamond from "../../../assets/img/smallDiamond.svg";
import { imageRoute } from "../../../utils/api";

type PropsBonusesItemType = {
  caseName: string;
  caseImage: string;
  caseCoin: number;
  robotName: string;
  robotDescr: string;
  itemImage: string;
  addCoin: number;
  date: string;
};

export function BonusesVerticalItem(props: PropsBonusesItemType) {
  return (
    <ItemWrapper>
      <DateItem>
        <img src={dateIcon} alt="" />
        {props.date}
      </DateItem>
      <LootWrapper>
        <ItemCase>
          <CaseImage>
            <ItemEffect />
            <img className="w-[55px] z-10" src={`${imageRoute}lootboxes/${props.caseImage}`} />
          </CaseImage>
          <CaseName>
            <div>{props.caseName}</div>
            <CristalCount>
              <img src={smallDiamond} alt="" />
              <span>{props.caseCoin} MIP</span>
            </CristalCount>
          </CaseName>
        </ItemCase>

        <ArrowVert />

        <ItemRobot>
          <RobotImage>
            <img className="w-[50px] z-10" src={props.itemImage} alt="" />
            <ItemEffect className="!left-0 opacity-50" />
          </RobotImage>
          <CaseName className="flex-col !items-start !ml-[10px]">
            <div>{props.robotName}</div>
            <DescrRobot>{props.robotDescr}</DescrRobot>
          </CaseName>
        </ItemRobot>
      </LootWrapper>
      <AddMIP>
        {props.addCoin && (
          <>
            <p>+ {props.addCoin} </p>
            <img src={smallDiamond} alt="" />
          </>
        )}
      </AddMIP>
    </ItemWrapper>
  );
}

const AddMIP = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #ffd63d;
  display: flex;
  justify-content: end;
  width: 80px;
  flex-shrink: 0;

  > img {
    margin-left: 9px;
  }
`;

const LootWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const RobotImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ItemRobot = styled.div`
  display: flex;
  flex-basis: 200px;
  flex-grow: 1;
`;

const DescrRobot = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #616d7a;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
`;

const DateItem = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
  width: 105px;
  flex-shrink: 0;

  > img {
    height: 17px;
    margin: 0 6px -2px 0;
  }
`;

const ItemCase = styled.div`
  display: flex;
  justify-content: end;
  width: 230px;
  flex-grow: 1;
  flex-shrink: 0;
`;

const CaseImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ItemEffect = styled(CircleIcon)`
  fill: #f82f90;
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0;
  left: -10px;
`;

const CaseName = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-evenly;

  > div:nth-child(1) {
    margin-right: 10px;
  }
`;

const ArrowVert = styled(ArrowHorizontItem)`
  margin: 0 50px;
  flex-shrink: 0;
`;

const CristalCount = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffd63d;
  }

  > img {
    margin: -1px 6px 0 0;
  }
`;
